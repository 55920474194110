:root {
  --Admin-gradiant: linear-gradient(
    0deg,
    rgba(122, 158, 160, 1) 0%,
    rgba(132, 168, 170, 1) 24%,
    rgba(134, 185, 185, 1) 48%,
    rgba(152, 188, 190, 1) 74%,
    rgba(122, 158, 160, 1) 100%
  );
}

.status-pending {
  color: blue !important;
}

/* file upload  */
.my-file-upload {
  text-align: center;
}

/* #4f6367 */
.prdct-img-cust {
  width: 20px;
  border-radius: 6px;
}

.my-file-upload p {
  font-size: 20px;
  color: white;
  font-weight: 300;
}

/* file upload  */

/* sidebar  */
section.admin-side-side {
  margin-left: auto;
  width: calc(100% - 350px);
}

.sidebaradmin .filepond--root.filepond--hopper {
  height: 120px !important;
}

.sidebaradmin .filepond--wrapper {
  width: 120px !important;
  margin: 0 auto;
}

.sidebaradmin .filepond--image-preview-wrapper {
  height: 120px;
  width: 130px;
}

.sidebaradmin .filepond--image-preview-overlay svg {
  width: 140px !important;
  height: 120px !important;
}

.sidebaradmin {
  width: 320px;
  background: var(--Admin-gradiant);
  border-radius: 15px;
  height: 95vh;
  left: 10rem;
  padding: 20px 0;
  /* display: flex; */
  /* flex-direction: column; */
  position: fixed;
  bottom: 20px;
}

.admin-menus {
  width: 80%;
  margin-left: auto;
  padding-top: 30px;
}

.admin-menus ul li a {
  font-size: 15px;
  color: white;
  font-weight: 600;
}
.admin-menus-2 ul li {
  margin-bottom: 0 !important;
}
.admin-menus ul li {
  display: flex;
  gap: 12px;
  position: relative;
  align-items: flex-start;
  padding: 7px 10px;
  cursor: pointer;
  margin-bottom: 25px;
}

.admin-logout a,
.admin-logout button {
  display: flex;
  background: #fff;
  color: var(--Slate);
  font-size: 13px;
  font-weight: 500;
  border-radius: 50px;
  justify-content: center;
  margin: 0 auto;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

/* ab  */
.admin-menus .dropdown-menu {
  position: relative !important;
  left: 0 !important;
  top: -11px !important;
  background: transparent;
  border: none !important;
  box-shadow: none !important;
  transform: translate(10px, 10px) !important;
}

.admin-menus button#dropdown-basic-button {
  background: none;
  border: none;
  padding: 0;
  font-weight: 600 !important;
  position: relative;
  font-size: 15px;
  width: 70px;
  text-align: left;
  box-shadow: none !important;
}

.admin-menus .dropdown-menu a {
  display: block !important;
  padding: 1px 10px;
}

.admin-menus button#dropdown-basic-button:before {
  content: "\f078";
  position: absolute;
  right: -70px;
  top: 0;
  font-family: "Font Awesome 6 Free", sans-serif;

  font-weight: 600;
}

.admin-logout {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

/* sidebar  */

/* front-page  */
.admin-front-page {
  background: var(--Antique-White);
  border-radius: 10px;
  padding: 10px 20px;
}

.hiadmin h1 {
  font-size: 25px;
  color: white;
  font-weight: 600;
}

.hiadmin h2 {
  font-size: 25px;
  font-weight: 200;
  color: white;
  padding-bottom: 30px;
}

.hiadmin p {
  font-size: 13px;
  color: white;
  font-weight: 400;
}

.cust-gap img {
  object-fit: contain;
}

.hiadmin {
  background: var(--Admin-gradiant);
  border-radius: 20px;
  position: relative;
  padding: 20px 50px;
}

h1.mean-admin-title {
  font-size: 25px;
  color: var(--Slate);
  font-weight: 500;
  margin-bottom: 0;

  text-transform: uppercase;
}

img.side-img-hiadmin {
  position: absolute;
  right: 19rem;
  bottom: 0;
  width: 19%;
  z-index: 9;
}

img.plus-bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 31%;
  z-index: 0;
}

.order-detail-img span {
  color: white;
}

.order-detail-img {
  background: var(--Admin-gradiant);
  border-radius: 10px;
  position: relative;
  padding: 7px 0px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.order-detail-content p {
  font-size: 13px;
  font-weight: 500;
  color: var(--Slate);
  margin: 0;
}

.order-detail-content span {
  font-size: 13px;
  font-weight: 600;
  color: var(--Slate);
}

.order-detail-content {
  text-align: center;

  padding-bottom: 13px;
}

.order-detail-box {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #707070;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 2px 1px 16px 0px #00000059;
  margin-bottom: 20px;
}

.row.order-detail-admin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.graph-one {
  padding: 0px;
}

.graph-two {
  padding: 0px;
}

.latest-order-box-parent {
  /* margin: 0px 72px 0px 0px; */
  border-radius: 23px;
  padding: 22px 18px 55px;
  position: relative;
  z-index: 1;
  box-shadow: 2px 1px 16px 0px #00000059;
}

.latest-order-box-parent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: white;
  border-radius: 23px;
}

.latest-order-flex {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 0px 16px;
}

.latest-order-flex span {
  font-size: 13px;
  font-weight: 600;
  color: var(--Slate);
}

.fi-order-box {
  border-radius: 50px;
  padding: 8px 0;
  margin: 0px 0px 8px;
  background: var(--Slate);
  font-size: 13px;
}

ul.order-list {
  display: grid;
  align-items: center;
  grid-template-columns: 17% 17% 17% 17% 17% 15%;
  text-align: center;
  list-style: none;
  padding: 0px;
  margin: 0;
}

ul.order-list li a {
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.sec-order-box {
  border: 1px solid black;
  border-radius: 30px;

  margin: 0px 0px 8px;
}

.sec-order-box:hover {
  background-color: var(--Pale-Aqua);
  /* transform: scale(1.1);

  transition: all 0.42s ease 0s; */
}

.sec-order-box:hover ul.sec-order-list li a {
  color: var(--Slate) white;
  transition: all 0.42s ease 0s;
}

/* .sec-order-box:hover ul.sec-order-list li a i {
  color: rgb(255, 255, 255);
  transition: all 0.42s ease 0s;
} */

ul.sec-order-list {
  display: grid;
  align-items: center;
  grid-template-columns: 17% 17% 17% 17% 17% 15%;
  text-align: center;
  list-style: none;
  padding: 0px;
  margin: 0;
}

ul.sec-order-list li a,
ul.sec-order-list li span {
  color: black;
}

ul.sec-order-list li:nth-child(3) a i {
  color: red;
}

/* ul.sec-order-list li:nth-child(3) a {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
} */

.row.graph-row-1 img,
.row.graph-row-2 img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row.graph-row-1 {
  background: white;
  box-shadow: 2px 1px 16px 0px #00000059;
  border-radius: 23px;
  margin: 30px 0px;
  padding: 30px 10px;
}

.row.graph-row-2 {
  display: flex;
  justify-content: center;
}

.row.graph-row-2 .col-lg-8 {
  background: white;
  box-shadow: 2px 1px 16px 0px #00000059;
  padding: 0 0;
  border-radius: 23px;
  margin: 0px 0px 30px;
  padding: 0px 10px;
}

.logoutmodal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}

.fade.logoutmodal.modal .modal-footer {
  border: none;
  justify-content: center;
}

.fade.logoutmodal.modal .modal-footer button {
  background: none;
  border: none !important;
  background-color: #fff;
  color: black !important;
  font-size: 20px;
  font-weight: 400;
}

.fade.logoutmodal.modal .modal-footer .cancel-btn {
  background: none;
  border: 1px solid var(--Slate) !important;
  background-color: transparent;
  color: var(--Slate) !important;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 46px;
  margin: 0 16px 0 0;
  border-radius: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.fade.logoutmodal.modal .modal-footer .logut-modal-btn {
  background: none;
  border: none !important;
  background-color: var(--Slate);
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 50px;
}

.fade.logoutmodal.modal .modal-footer a {
  background: none;
  border: none;
  background-color: #fff;
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.fade.logoutmodal.modal .modal-footer a:last-child {
  color: red;
  margin-left: 51px !important;
}

.fade.logoutmodal.modal h1 {
  font-size: 16px;
  font-weight: 400;
  /* text-transform: lowercase; */
  text-align: left;
}

.fade.logoutmodal.modal .modal-content {
  padding: 0 0px;
  border: none;
  box-shadow: none;
  border-radius: 18px;
}

.logoutmodal.modal .modal-body {
  padding: 0 0px;
}

/* front-page  */

/* practionar page  */
.practionar-request .latest-order-box-parent {
  padding: 0 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.practionar-request .sec-order-box:hover {
  background: var(--Pale-Aqua);
  transform: initial;
}

/* section.admin-side-side.table-boxes {
  padding-top: 40px;
} */
.practionar-request .sec-order-box:hover ul.sec-order-list li a {
  color: inherit;
  transition: all 0.42s ease 0s;
}

.practionar-request .sec-order-box:hover ul.sec-order-list li a i {
  color: initial;
}

.practionar-request .fi-order-box {
  background: var(--Slate);
  padding: 10px 0;
  border-radius: 5px;
  color: white;
}

.practionar-request ul.order-list li a {
  color: white;
}

.practionar-request ul.order-list {
  grid-template-columns: 16% 20% 17% 18% 29%;
}

.practionar-request ul.sec-order-list {
  grid-template-columns: 16% 20% 17% 18% 29%;
}

.practionar-request .sec-order-box {
  background: var(--Pale-Aqua);
  border: none;
  border-radius: 5px;
  height: 50px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.practionar-request .admin-logout {
  position: initial;
  transform: inherit;
  display: flex;
  gap: 10px;
  justify-content: center;
  text-wrap: nowrap;
}

.sec-order-box:hover .admin-logout a {
  color: white !important;
}

.practionar-request .admin-logout a {
  background: var(--Slate);
  color: white;
}

.sec-order-box ul.sec-order-list .underline span {
  text-decoration: underline !important;
  font-size: 13px;
  cursor: pointer !important;
}

.practionar-request.customer ul.order-list {
  grid-template-columns: 15% 15% 15% 13% 20% 22%;
}

.sec-order-box .admin-logout {
  margin: 0;
}

.practionar-request.customer ul.sec-order-list {
  grid-template-columns: 15% 15% 15% 13% 20% 22%;
}

.practionar-request.customer.pract-request ul.order-list {
  grid-template-columns: 20% 25% 25% 23%;
}

.practionar-request.customer.pract-request ul.sec-order-list {
  grid-template-columns: 20% 25% 25% 23%;
}

.practionar-request.customer.pract-request .admin-logout a,
.practionar-request.customer.pract-request .admin-logout button {
  background: #d6e9e9 !important;
  color: #616161 !important;
  border: 1px solid #61616178 !important;
}

.set-color-icn-bt .restore-btn-cust {
  background: #d6e9e9;
  color: #616161;
  border: 1px solid #61616178;
  border-radius: 50px;
  padding: 4px 12px;
  margin-right: 6px;
  font-size: 14px;
}

.practionar-request.customer.pract-request .admin-logout a:last-child:hover,
.practionar-request.customer.pract-request
  .admin-logout
  button:last-child:hover {
  background: red !important;
  color: white !important;
}

.practionar-request.customer.pract-request .admin-logout a:first-child:hover,
.practionar-request.customer.pract-request
  .admin-logout
  button:first-child:hover {
  background: green !important;
  color: white !important;
}

.fade.logoutmodal.delet-modale.modal .modal-footer a:last-child {
  margin: 0 !important;
  border: 0px solid #707070 !important;
}

.fade.logoutmodal.delet-modale.modal .modal-footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
}

.fade.logoutmodal.delet-modale.modal .modal-footer button {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  border-radius: 50px;
  color: #fff !important;
  border: 1px solid #707070 !important;
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.fade.logoutmodal.delet-modale.modal .modal-footer .color-set-modal {
  border-radius: 50px;
  color: #000 !important;
  border: 1px solid #707070 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  width: 130px;
}

.fade.logoutmodal.delet-modale.modal .modal-footer .delete-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  width: 130px;
  border-radius: 50px;
  background-color: #f22626;
  color: #fff;
  border: 1px solid #707070 !important;
}

.fade.logoutmodal.delet-modale.modal h1 {
  font-size: 13px;
  line-height: 40px;
  text-align: center;
}

.cust-gap {
  padding: 0 26px 0 0;
}

.fade.logoutmodal.delet-modale.modal .modal-content {
  background: #e8e8e8;
  padding: 20px 0px !important;
}

h1.mean-admin-title i {
  margin-right: 29px;
  color: var(--Slate);
  font-size: 19px;
}

.how.did label {
  height: 40px;
  /* font-size: 18px; */
}

/* practionar page  */

/* detail-page */

.detail-page-test h1 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  color: rgb(46, 150, 215);
}

.detail-page-test label {
  font-size: 13px;
}

.detail-page-test {
  background: #1282cd14;
  padding: 25px 20px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.test-field input {
  width: 100%;
  font-size: 13px;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  height: 45px;
  padding: 0px 24px;
  background: transparent;
}

.test-field {
  margin-bottom: 28px;
}

.test-field label {
  font-size: 13px;
  font-weight: 400;
  color: #797f82;
  padding-bottom: 5px;
}

.test-field input:focus {
  border: 1px solid #087bca;
}

.test-field.cit-st {
  display: flex;
  width: 100%;
  gap: 40px;
}

.test-field input:focus-visible {
  outline: 1px solid #087bca;
}

.test-field.cit-st div {
  width: 100%;
}

.test-btn button {
  color: #fff !important;
  font-size: 13px;
  font-weight: 300;
  width: 130px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.test-btn {
  text-align: end;
  margin-top: 10px;
}

.das-bank-detail .test-field input::placeholder {
  color: #c7c7c7;
}

.detail-page-test.das-bank-detail input {
  margin-bottom: 20px;
}

.detail-page-test.das-bank-detail {
  margin-top: 50px;
  margin-bottom: 100px;
}

.gender-customer label.partici-character {
  display: block;
  font-size: 20px;
}

.gender-customer {
  display: flex;
  gap: 40px;
  text-align: center;
  margin-bottom: 30px;
}

.gender-customer input {
  width: 26px;
  height: 25px;
  background: transparent;
}

label.gender-label {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 5px;
}

.testall ul.order-list {
  grid-template-columns: 17% 17% 17% 15% 17% 17%;
  border-radius: 6px;
  background: var(--Slate);
  padding: 10px 0;
  margin-bottom: 8px;
  margin-top: 10px;
}

.testall.testall ul.order-list li a {
  color: white;
  font-weight: 500;
  font-size: 13px;
}

.two-lnk {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.two-lnk li a {
  color: var(--Slate) !important;
}

.two-lnk li {
  background: #ffffff;
  border: 1px solid;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 140px;
  height: 31px !important;
  margin-bottom: 15px;
  margin-top: 15px;
}

.testall.testall .fi-order-box {
  padding: 0;
  margin: 0;
}

.testall.testall ul.order-list li {
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 0px solid black;
  justify-content: center;
}

.testall .sec-order-box {
  padding: 0 0;
  margin: 0;
  border-radius: 5px;
  border: 0;
  background: var(--Pale-Aqua);
  margin-bottom: 0.5rem;
}

.testall ul.sec-order-list li {
  border-right: 0px solid #929292;
  height: 100%;
  padding: 10px 0;
}

.testall ul.sec-order-list li p {
  color: #5d5d5d;
  margin: 0;
}

.testall ul.sec-order-list {
  grid-template-columns: 17% 17% 17% 15% 17% 17%;
}

.testall ul.order-list ul p {
  border-bottom: 1px solid black;
  color: white;
  margin-top: 10px;
}

.testall ul.order-list ul {
  border-right: 1px solid black;
}

.sidebaradmin .filepond--drop-label.filepond--drop-label label {
  font-size: 10px;
}

.practionar-request.customerlist ul.order-list {
  grid-template-columns: 12% 12% 14% 12% 12% 12% 14% 12%;
}

.practionar-request.customerlist ul.sec-order-list {
  grid-template-columns: 12% 12% 14% 12% 12% 12% 14% 12%;
}

.testall {
  margin-bottom: 90px;
}

.admin-menus .dropdown-toggle::after {
  display: none;
}

.Patient-report-name {
  background: var(--Admin-gradiant);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 60px 12rem;
  margin-top: 2rem;
}

.Patient-report-name h1 {
  font-size: 50px;
  color: white;
  font-weight: 500;
}

.Patient-report-name h2 {
  color: white;
  font-size: 30px;
  font-weight: 500;
}

.Patient-report-name p {
  font-size: 20px;
  color: white;
  font-weight: 400;
}

.Patient-report-name img {
  position: absolute;
  right: 6rem;
  top: -120px;
  width: 50%;
}

section.admin-side-side.report-custom {
  padding-left: 2rem;
  position: relative;
}

.summary h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--Slate);
}

.dashboards.customer-dashboard span.arrow-back {
  left: 0;
}

/* detail-page */

/* doc-page */

.summary p {
  font-size: 13px;
  font-weight: 400;
  color: #797f82;
}

.Doc-name {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  gap: 18px;
  background: var(--Pale-Aqua);
  border-radius: 10px;
}

.Doc-name p {
  font-size: 24px;
  color: #2f81b8;
  font-weight: 500;
}

.row.sum-row p {
  font-size: 13px;
  font-weight: 400;
  color: #797f82;
}

.row.sum-row .Doc-name p {
  font-size: 24px;
  color: var(--Slate);
  font-weight: 600;
}

.row.sum-row {
  padding: 40px 0;
}

.test-nameone {
  background: #7a9ea087;
  padding: 1rem 2rem;
  margin-bottom: 40px;
  border-radius: 20px;
}

.iron-stove {
  padding: 40px 70px;
  border-radius: 20px;
  background: #7a9ea0;
  margin-bottom: 30px;
}

.iron-stove h1 {
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.iron-stove p {
  font-size: 13px;
  font-weight: 400;
  color: white;
}

.fertin {
  padding: 40px 70px;
  border-radius: 20px;
  background: #ffffff;
  margin-bottom: 30px;
}

.result-history-btn a {
  background: var(--Pale-Aqua);
  border-radius: 20px;
  color: var(--Slate);
  padding: 7px 20px;
  position: relative;
  font-size: 13px;
  top: 8px;
}

.result-graph img {
  width: 100%;
  height: 100%;
}

.result-graph {
  background: white;
  border-radius: 20px;
  box-shadow: 0px 6px 12px 0px black;
  padding-top: 25px;
  height: 100%;
}

.result-hs {
  background: white;
  border-radius: 20px;
  box-shadow: 0px 6px 20px 12px #0d6efd47;
  padding: 10px 19px;
}

.date-hist {
  background: var(--Pale-Aqua);
  border-radius: 30px;
  padding-bottom: 9px;
}

.date-hist h4 {
  font-size: 20px;
  color: white;
  font-weight: 400;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.fordate {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 6px;
}

.fordate p {
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.fordate span {
  width: 100px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #2eceb3;
}

.row.history-row {
  padding-bottom: 50px;
}

/* doc-page */

/* range slider */
.range-sld
  span.MuiSlider-root.MuiSlider-marked.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-1aznpnh-MuiSlider-root {
  height: 9px !important;
  padding: 0 !important;
}

.range-sld .MuiBox-root.css-1z13ce1 {
  position: relative;
}

.red-res1 {
  background: red;
  width: 33%;
  height: 9px;
  position: absolute;
  top: 0px;
  border-radius: 10px;
  z-index: 0;
}

.red-res2 {
  background: #86ef1d;
  width: 33%;
  height: 9px;
  position: absolute;
  top: 0;
  border-radius: 10px;
  z-index: 0;
  left: 33%;
}

.red-res3 {
  background: red;
  width: 33%;
  height: 9px;
  position: absolute;
  top: 0px;
  border-radius: 10px;
  z-index: 0;
  left: 67%;
}

.range-sld
  span.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-eg0mwd-MuiSlider-thumb {
  z-index: 999;
  color: #1d8def !important;
}

.range-sld .css-1aznpnh-MuiSlider-root {
  color: transparent !important;
}

.title-test {
  padding-bottom: 70px;
  text-align: end;
}

.title-test h2 {
  color: #86ef1d;
  font-size: 25px;
  font-weight: 500;
}

.second-test.test-nameone {
  background: #7a9ea087;
}

.second-test.test-nameone .iron-stove {
  background: white;
}

.second-test.test-nameone .iron-stove h1 {
  color: var(--Slate);
}

.second-test.test-nameone .iron-stove p {
  color: #797f82;
}

/* recom-box-style  */

.inner-recom-box {
  background: white;
  border-radius: 25px;
  padding: 20px 20px;
  position: relative;
  width: 460px;
  height: 270px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: baseline;
}

.recom-right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-recom-box h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--Slate);
}

.inner-recom-box p {
  font-size: 13px;
  font-weight: 400;
  color: #797f82;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.inner-recom-box a {
  background: var(--Pale-Aqua);
  border-radius: 20px;
  color: var(--Slate);
  padding: 7px 20px;
  position: relative;
  font-size: 13px;
}

.inner-recom-box img {
  position: absolute;
  right: 40px;
  bottom: 20px;
  /* filter: saturate(0) brightness(1) invert(2) !important; */
}

h1.recom-title {
  font-size: 20px;
  color: var(--Slate);
  font-weight: 600;

  text-align: center;
  margin-bottom: 50px;
}

.recomendation-box {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.recom-right-box .inner-recom-box {
  height: 270px;
  display: block;
}

.second-test.test-nameone.practitioner-note h1.recom-title {
  background: white;
  color: var(--Slate);
  padding: 16px 10px;
  font-weight: 600;
  border-radius: 15px;
  font-size: 20px;
}

.second-test.test-nameone.practitioner-note {
  padding-top: 30px;
  padding-bottom: 40px;
}

.pract-Notes ul li {
  font-size: 13px;
  font-weight: 400;
  color: var(--Slate);
  margin-bottom: 10px;
  list-style: disc;
}

.pract-Notes ol li {
  font-size: 13px;
  font-weight: 400;
  color: var(--Slate);
  margin-bottom: 10px;
  list-style: decimal;
}

.pract-add-Notes {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 10px 10px;
}

.back-dash {
  text-align: center;
  margin-bottom: 50px;
}

.back-dash p {
  font-size: 20px;
  color: var(--Slate);
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.back-dash a {
  font-size: 13px;
  font-weight: 500;
  color: white;
  background: var(--Slate);
  border-radius: 50px;
  padding: 14px 40px;
}

.print-btn button {
  font-size: 18px;
  font-weight: 500;
  color: white;
  background: var(--Slate);
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 50px;

  margin-left: auto;
}
.print-btn.print-result {
  display: grid;
  place-content: center;
}
.print-btn {
  text-align: center;
  margin-bottom: 30px;
}

.instruction-btn {
  margin-top: 10px;
}

span.arrow-back {
  position: absolute;
  left: 25px;
  top: 0;
}

.dashboards {
  overflow-x: hidden;
}

span.arrow-back i {
  color: var(--Slate);
  font-size: 30px;
}

/* recom-box-style  */

/* admin css change */
.dashboards.product-admin .practionar-request ul.order-list {
  grid-template-columns: 16% 16% 17% 17% 18% 16%;
}

.dashboards.product-admin .practionar-request ul.sec-order-list {
  grid-template-columns: 16% 16% 17% 17% 18% 16%;
}

.showTable ul {
  display: flex;
  margin-bottom: 0px;
}

.showTable a.login-btn {
  color: white;
  border: none;
  border-radius: 50px;
  background-color: var(--Slate);
  padding: 9px 30px;
  font-size: 13px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.showTable ul li a {
  margin-right: 20px;
  color: var(--Slate);
  font-size: 13px;
  font-weight: 400;
}

.categoriesFilter select {
  background: #d2d2d2;
  border: none;
  padding: 15px 30px 15px 0;
  font-size: 15px;
  font-weight: 300;
  color: #717475;
  border-radius: 5px;
  margin-right: 20px;
}

.categoriesFilter select option {
  border: none;
}

.categoriesFilter {
  display: flex;
  justify-content: space-between;
}

.search-product input {
  width: 480px;
  background: #d2d2d2;
  border: none;
  padding: 8px 30px 8px 45px;
  font-size: 15px;
  font-weight: 300;
  color: #717475;
  border-radius: 5px;
}

.search-product {
  position: relative;
}

.search-product i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translatey(-50%);
  color: #c4c3c3;
  font-size: 21px;
}

.productadminform .form-group label {
  color: var(--Slate);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.productadminform .form-group input {
  border: none;
  background: white;
  padding: 8px 14px;
  font-size: 13px;
}

.productadminform .form-group textarea {
  border: none;
  background: white;
  padding: 14px 15px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  font-size: 13px;
}

.productadminform .form-group {
  margin-bottom: 15px;
}

.showTable {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
  align-items: center;
}

a.active.dropdown-item {
  background: white;
  border-radius: 10px 0px 0px 10px !important;
  color: black;
}

.addproduct-tabs ul.nav.nav-tabs {
  gap: 20px;
  border: none;
  margin-bottom: 30px;
}

.addproduct-tabs li.nav-item .nav-link {
  background: transparent;
  border: none;
  border: 1px solid var(--Slate);
  padding: 9px 20px;
  border-radius: 7px;
  font-size: 13px !important;
  font-weight: 400;
  color: var(--Slate);
}

.addproduct-tabs li.nav-item .nav-link.active {
  background: var(--Slate);
  color: white;
}

.productadminform {
  border: none;
  background: var(--Pale-Aqua);
  padding: 14px 30px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
}

.productadminform .close-mod {
  background: var(--Slate);
  font-size: 13px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
}

.productadminform select {
  background: white;
  color: #717475;
  font-weight: 400;
  font-size: 13px;
}

.productadminform .search-product input {
  background: white;
  color: black;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
}

.productadminform .categoriesFilter {
  justify-content: space-evenly;
}

.productadminform .categoriesFilter div {
  display: grid;
}

.productadminform label {
  margin-bottom: 10px;
  color: var(--Slate);
  font-size: 13px;
  font-weight: 500;
}

.biomarkerbtn a {
  background: var(--Slate);
  padding: 9px 30px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.biomarkeraddbtn {
  background: var(--Slate);
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.biomarkerbtn button {
  background: var(--Slate);
  padding: 9px 30px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.bioaddremove label.btn.btn-outline-primary {
  border: none;
  background: var(--Slate);
  color: white;
  display: flex;
  gap: 9px;
  align-items: center;
}

.biomarkerbtn {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.biomarker-table-head {
  background: var(--Slate);

  border-radius: 5px;
  color: white;
  grid-template-columns: 20% 25% 25% 23%;
  margin-bottom: 11px;
}

.biomarker-table-head ul li {
  padding: 12px 0;
  border-left: 1px solid white;
  font-size: 13px;
  font-weight: 600;
}

.biomarkertbhead ul {
  display: grid;
  align-items: center;
  grid-template-columns: 15% 15% 35% 35%;
  text-align: center;
  list-style: none;
  padding: 5px 0px;
  margin: 0;
  border-radius: 15px;
  background: var(--Slate);
  color: white;
  font-size: 13px;
}

.biomarkertbhead ul li {
  font-size: 13px;
  font-weight: 600;
}

.biomarkertbhead ul li input {
  width: 34%;
  font-size: 13px;
}

.biomarkertbhead {
  margin-bottom: 10px;
}

/* swith button  */
.productadminform input.switch {
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 35px !important;
  height: 20px;
  border-radius: 30px;
  cursor: pointer;
  background: #ccc;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: 0.1s, 0s;
}

.productadminform input.switch:focus {
  border: 0;
  outline: 0;
}

.productadminform input.switch:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 2.62857em var(--Slate) !important;
  padding-left: 15px;
}

.productadminform input.switch:before {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 3px;
  background-color: #fff;
  border-radius: 2.1em;
  transition: all 0.1s ease 0.1s;
}

.productadminform input.switch:before,
.productadminform input.switch:after {
  content: "";
  display: block;
}

.productadminform input.switch:active::before {
  transition: all 0.1s ease 0.05s;
}

.biomarker-table-head ul {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 15% 22% 30% 23%;
  text-align: center;
  list-style: none;
  padding: 0px;
  margin: 0;
}

.biomarker-table-body ul {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 15% 22% 30% 23%;
  text-align: center;
  list-style: none;
  padding: 0px;
  margin: 0;
  border-bottom: 1px solid white;
}

.biomarker-table-body {
  background: var(--Pale-Aqua);
}

.biomarker-table-body ul li {
  border-left: 1px solid #686868;
  padding: 5px 10px;
  height: 100%;
  font-size: 13px;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  color: #686868;
}

.biomarker-table-body ul li:first-child {
  border-left: none;
}

.biomarker-table-head ul li:first-child {
  border-left: none;
}

.add-marker-modal .modal-content {
  background: none;
}

.add-marker-modal .modal-dialog {
  width: 56%;
  max-width: 47%;
}

.add-marker-modal .productadminform .form-group {
  text-align: left;
}

.add-marker-modal .biomarkerbtn {
  padding: 0;
}

select.mesurment {
  border: none;
  background: white;
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
  color: black !important;
  font-size: 13px;
}

.productadminform .form-group input::placeholder {
  color: #00000063 !important;
  font-size: 13px;
}

.productadminform .form-group textarea::placeholder {
  color: #00000063 !important;
  font-size: 13px;
}

.test-print {
  display: flex;
  justify-content: space-between;
}

/* blood-page style */
.test-print span {
  display: flex;
  align-items: center;
  gap: 20px;
}

.blood-test-row ul {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 40% 20%;
  text-align: center;
  list-style: none;
  margin: 0;
  background: var(--Slate);
  color: white;
  padding: 6px 0;
  border-radius: 11px;
}

.blood-test-row ul li {
  text-align: left;
  padding-left: 30px;
  border-right: 1px solid 0000004d;
  height: 100%;
  display: flex;

  align-items: center;
  font-size: 13px;
}

.blood-test-row ul li:nth-child(2) {
  border: none !important;
}

.blood-test-row ul li:nth-child(3) {
  text-align: end;
  padding-right: 31px;
  justify-content: end;
}

.Add-new-test {
  padding: 20px 20px;
  background: var(--Pale-Aqua);
  border-radius: 14px;
  display: none;
}

.blood-test-field .row {
  padding: 10px 20px;
  background: var(--Pale-Aqua);
  border-radius: 14px;
  margin: 30px 0px;
}

.blood-test-field .row input {
  border: none;
  background: white;
  padding: 14px 30px;
  font-size: 13px;
}

.blood-test-field .row textarea {
  border: none;
  background: white;
  padding: 4px 30px;
  width: 70%;
  height: 100px;
  border-radius: 10px;
}

.blood-test-field .row label {
  margin-bottom: 10px;
  color: var(--Slate);
  display: block;
  font-size: 13px;
  font-weight: 600;
}

.blood-test-field .form-group {
  padding-right: 30px;
}

.blood-test-body .blood-test-row ul {
  background: white;
  border: 1px solid var(--Slate);
  margin-top: 20px;
}

.blood-test-body .blood-test-row ul li {
  color: #515151;
}

.blood-test-body .blood-test-row ul li:last-child {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  border: none;
}

.blood-test-body .blood-test-row ul li:last-child a i {
  color: var(--Slate);
}

.Add-new-test.show {
  display: block;
}

.skurow .form-group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.skurow .form-group label {
  width: 200px;
}

.blue {
  color: var(--Slate);
}

.bioselector {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
}

.bioselector label {
  background: #ffffff;
  border: none;
  color: black;
  padding: 10px 30px;
}

.bioselector .btn-check + .btn:hover {
  background: #248ed3 !important;
  color: white;
}

.fade.logoutmodal.bio-marker-modal.modal.show .modal-dialog {
  max-width: 38%;
}

.fade.logoutmodal.bio-marker-modal.modal.show .modal-content {
  background: #e1e1e1;
}

.fade.logoutmodal.bio-marker-modal.modal.show .biomarkerbtn {
  margin-top: 20px;
  padding: 0;
}

.fade.logoutmodal.bio-marker-modal.modal.show .modal-body h1 {
  color: var(--Slate);
  font-size: 20px;
  margin-bottom: 20px;
}

.form-drop .MuiFormControl-root.css-2m9kme-MuiFormControl-root {
  width: 100%;
  background: white;
  border: none;
  border-radius: 5px;
}

.form-drop label#demo-multiple-checkbox-label {
  font-size: 16px;

  /* display: none; */
}

a.filepond--credits {
  display: none;
}

.print-btn a {
  background: var(--Slate);
  font-size: 13px;
  color: white;
  border-radius: 40px;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.form-group.uploadimg span.filepond--label-action i {
  font-size: 50px;
  color: #172e5878;
}

.form-group.uploadimg .filepond--wrapper {
  width: 60%;
  background: #e6e6e6;
}

.form-group.uploadimg .filepond--wrapper .filepond--drop-label {
  border: 1px solid var(--Slate);
  border-radius: 8px;
}

/* blood-page style */

/* admin pages health profile */
/* -------------- health profile --------------- */

.update-btn-health a {
  background: #087bca;
  color: #fff;
  padding: 8px 40px;
  border-radius: 20px;
}

.update-btn-health {
  justify-content: flex-end;
  padding-top: 40px;
}

.basic-heading-hlth-prof h1 {
  padding-top: 0px;
  font-size: 35px;
}

.basic-info-health-profile .privacy-line {
  padding: 18px 0px;
}

input#smoke-no {
  margin-left: 20px;
}

.do-you-smoke p {
  padding: 6px 0px;
}

.steps {
  display: flex;
  width: 100%;
  /* max-width: 1000px; */
  justify-content: space-around;
  align-items: center;
  /* margin: 50px auto; */
  position: relative;
}

.steps div {
  width: 100%;
  height: 2px;
  background: #c5c5c5;
  position: relative;
}

.steps div::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}

.steps div.active::after {
  transform: scaleX(1);
}

.steps button {
  cursor: pointer;
  background: transparent;
  border: 2px solid #318cfc;
  color: #318cfc;
  font-weight: 900;
  border-radius: 100%;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 31px;
  transition: all 0.3s ease;
}

.steps button.active {
  background: #318cfc;
  color: white;
}

.contents {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  box-sizing: border-box;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.content.active {
  opacity: 1;
  z-index: 10;
}

.content__box {
  background: #d7dbde;
  border-radius: 20px;
  position: relative;
  padding: 37px 15px;
  color: #797f82;
  top: 37px;
  width: 32%;
}

.content__box:before {
  content: "";
  position: absolute;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: #d7dbde;
  width: 20px;
  height: 14px;
  top: -13px;
}

.range-bar-slider .main-line {
  padding: 20px 0px;
}

.steps p {
  position: absolute;
  margin: 7px -30px;
}

.cntr-p {
  transform: translateX(-50px);
}

.steps .last-p {
  position: absolute;
  right: 0;
  transform: translateY(20px);
}

.content__box_1 {
  margin: 0 auto;
}

.content__box_1:before {
  left: 50%;
}

.content__box_2:before {
  right: 20px;
}

.content__box_2 {
  right: 0;
  margin-left: auto;
}

.text-area-health-profile textarea {
  width: 100%;
  padding: 13px;
  outline: none;
  background: transparent;
  margin: 13px 0px;
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

/* -------- range slider ------------- */

.range-container {
  position: relative;
  margin: 20px 0px;
}

#range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: transparent;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#range-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

#range-slider::-webkit-slider-thumb::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -9px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

#range-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

#range-slider::-moz-range-thumb::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -9px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.points-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.point {
  width: 10px;
  height: 10px;
  background: #ccc;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

#fertility-no,
#birth-no,
#rcnt-birth,
#syndrome-not-sure,
#syndrome-no,
#pregnancies-no,
input#menstural-no {
  margin-left: 20px;
}

.radio-inp-m {
  margin-top: 10px;
}

.basic-info {
  background-color: #f5fafd;
  padding: 41px 65px;
  margin: 8px 0px;
  border-radius: 20px;
}

.inp-grid {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 32% 32% 32%;
}

.inp-grid label {
  color: #46a6df;
  font-size: 20px;
}

.inp-grid-health-pfr {
  width: 70%;
}

.basic-info p {
  color: #c5c5c5;
  font-size: 20px;
}

.basic-info p span {
  color: #22b6f2;
}

.inp-grid input {
  width: 76%;
  display: block;
  padding: 7px 5px;
  margin: 10px 0px;
  outline: none;
  border: none;
  border: 1px solid #4444;
  border-radius: 4px;
  background-color: transparent;
}

.basic-heading h1 {
  color: #087bca;
  font-weight: 600;
  padding-top: 0px;
  font-size: 45px;
}

.basic-info-health-profile {
  margin: 40px 0px;
}

.crrnt-pass label {
  display: block;
  color: #c5c5c5;
}

.crrnt-pass input {
  width: 100%;
  padding: 13px;
  outline: none;
  background: transparent;
  margin: 13px 0px;
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

.card-inpt {
  margin-top: 20px;
}

.crrnt-pass {
  color: #797f82;
}

section.edit-profileSec {
  padding-top: 90px;
}

.edit-profile-heading {
  background: #1282cd;
  margin: 65px auto 0px;
  text-align: center;
  color: #fff;
  padding: 37px;
  border-radius: 20px;
}

.edit-profile-heading h1 {
  font-size: 50px;
  font-weight: 400;
}

.edit-profile-heading h1 span {
  display: block;
  font-weight: 500;
}

.content__box p {
  color: #797f82;
  text-align: center;
}

.do-you-smoke label {
  color: #c5c5c5;
  margin-left: 7px;
}

.rangetabs ul.mb-3.nav.nav-tabs {
  justify-content: space-between;
  border: none;
  border-top: 1px solid black;
}

.rangetabs ul.mb-3.nav.nav-tabs li button {
  background: none;
  border: none;
  position: relative;
}

.nav-link.active {
  position: relative;
}

.rangetabs .nav-link:before {
  background: #797f82;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: -10px;
  content: "";
  border-radius: 70%;
}

.rangetabs ul.mb-3.nav.nav-tabs li:last-child button:before {
  right: 0;
  left: auto;
}

.rangetabs ul.mb-3.nav.nav-tabs li:nth-child(2) button:before {
  right: 50%;
  left: auto;
}

/* operators */
section.admin-side-side.operatosadmin .practionar-request ul.order-list {
  grid-template-columns: 17% 13% 17% 17% 21% 15%;
}

section.admin-side-side.operatosadmin .practionar-request ul.sec-order-list {
  grid-template-columns: 17% 13% 17% 17% 21% 15%;
}

section.admin-side-side.operatosadmin .form-group {
  display: flex;
  margin-bottom: 29px;
  position: relative;
}

section.admin-side-side.operatosadmin .form-group label {
  width: 35%;
  font-size: 13px !important;
  display: flex;
  align-items: center;
}

section.admin-side-side.operatosadmin .form-group input {
  height: 40px;
  border: 1px solid #707070;
  font-size: 13px !important;
}

span.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
}

section.admin-side-side.operatosadmin .form-group select {
  height: 40px;
  border: 1px solid #707070;
}

span.password-toggle i {
  color: #9f9f9f;
}

.dashboards.Buildownadmin .practionar-request ul.order-list {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.dashboards.Buildownadmin
  .practionar-request
  ul.sec-order-list
  li:nth-child(3)
  a {
  display: block;
}

.dashboards.Buildownadmin .practionar-request ul.sec-order-list {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

/* build test own addd */
.row.addtestbuildrow .form-group input {
  width: 100%;
  height: 40px;
  background: #f3f3f3;
  border: 1px solid #707070;
  padding: 0px 20px;
  font-size: 13px;
}

.row.addtestbuildrow .form-group input::placeholder {
  font-size: 13px;
  font-weight: 300;
  color: #707070;
}

.addNcut {
  padding: 10px 50px;
  background: #f3f3f3;
  margin-top: 20px;
}

.addtestname {
  background: var(--dark-Pale-Aqua);
  padding: 40px 28px;
  border-radius: 10px;
}

.addtestname ul li span {
  color: white;
  display: flex;
  gap: 60px;
  align-items: center;
  font-size: 13px;
}

.addprice-sku {
  background: var(--Pale-Aqua);
  margin-top: 20px;
  border-radius: 10px;
  padding: 40px 28px;
}

.addprice-sku label {
  width: 100%;
  color: #232323;
  font-size: 13px;
  margin-bottom: 6px;
}

.biomarkerlivepreview .cart-inner-own-test h1 {
  font-size: 13px;
  font-weight: 600;
}

.addprice-sku input {
  width: 100%;
  color: #232323;
  font-size: 16px;
  margin-bottom: 6px;
  height: 50px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  padding: 0px 20px;
}

.biomarkers-add {
  padding: 20px 30px;
  background: #f3f3f3;
  margin-top: 20px;
}

.biomarkers-add ul {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
}

.biomarkers-add ul li {
  background: var(--Slate);
  display: flex;
  padding: 10px 20px;
  border-radius: 6px;
  color: white;
  align-items: center;
  font-size: 13px;
  gap: 25px;
}

h2.label-title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0px;
  color: var(--Slate);
}

.biomarkerlivepreview {
  padding: 10px 50px;
  background: #f3f3f3;
}

.biomarkerlivepreview h2 {
  font-size: 18px;
  font-weight: 700;

  color: var(--Slate);
  padding-top: 0px;
  text-align: center;
  padding-bottom: 20px;
}

.publishbtn {
  background: var(--Slate);
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  margin-top: 60px;
  display: block;
  text-align: center;
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.showentries select {
  width: 130px;
  padding: 7px 10px;
  border-radius: 6px;
  background: #dcdcdc;
}

.showentries {
  display: flex;
  gap: 15px;
  align-items: center;
}

.row-fi-order-box ul.order-list {
  grid-template-columns: 7% 15% 17% 10% 24% 15% 12%;
}

.body-data ul.sec-order-list {
  grid-template-columns: 7% 15% 17% 10% 24% 15% 12%;
}

.row-fi-order-box {
  border-radius: 12px;
  padding: 16px 0;
  margin: 0px 0px 18px;
  background: var(--Slate);
}

.body-data {
  border: 1px solid black;
  border-radius: 13px;
  padding: 10px 0;
  margin: 0px 0px 18px;
}

.body-data ul.sec-order-list li a i {
  background: var(--Slate);
  padding: 5px 6px;
  color: white;
  border-radius: 3px;
}

.order-lsit ul.sec-order-list li a {
  font-size: 13px;
}

.body-data:hover {
  background: var(--Pale-Aqua);
}

.body-data:hover ul li a {
  color: white;
}

.body-data:hover ul li a i {
  background: white;
  color: black;
}

.order-lsit {
  padding: 30px 8px;
  border: 1px solid #d4d0d0;
  border-radius: 10px;
}

.filter-reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filter-reset span {
  color: var(--Slate);
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}

.filter-reset p {
  color: #087bca;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}

.filter-reset input {
  width: 100%;
}

.orderlist-form input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #e5e2e2;
  padding: 0 13px;
  margin-bottom: 8px;
  color: #a7a6a6;
  font-size: 13px;
}

.orderlist-form input::placeholder {
  color: #a7a6a6;
}

.orderlist-form label {
  color: var(--Slate);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
}

.orderlist-form select {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #e5e2e2;
  padding: 0 13px;
  margin-bottom: 8px;
  color: #a7a6a6;
  font-size: 16px;
}

.orderlist-form {
  width: 380px;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 1px 0px 8px 3px #d2cece;
}

.order-apply button {
  background: var(--Slate);
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 39px;
  border-radius: 30px !important;
}

.order-apply {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: end;
}

.order-lsit .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: var(--Slate);
  color: white;
}

.icon-pr-fr {
  display: flex;
  gap: 10px;
}

.order-invoice {
  display: flex;
  justify-content: space-between;
  background: var(--Antique-White);
  border-radius: 10px;
  align-items: center;
}

.active-pagination {
  background: var(--Slate);
}

.pginte-curser {
  cursor: pointer;
  font-size: 13px;
}

.active-pagination {
  background: var(--Slate);
  color: #fff !important;
  padding: initial;
  border-radius: 50%;
  font-size: 13px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-invoice span {
  font-size: 13px;
  color: black;
  font-weight: 600;
  padding: 20px 0px 20px 20px;
}

.order-invoice span p {
  color: #7b7a7a;
  font-weight: 400;
  margin: 0;
}

.order-invoice i {
  background: white;
  height: 100%;
  display: block;
  padding: 35px 4px;
  border: 1px solid var(--Slate);
  border-radius: 8px;
  font-size: 20px;
  color: var(--Slate);
}

.grid-sales-inner-parent {
  display: grid;
  grid-template-columns: 16% 16% 10% 16% 13% 12% 17%;
  background: var(--Slate);
}

.grid-sales-inner-cont {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-sales-inner-parent-two {
  background: transparent;
}

.brdre-right-para-sale {
  border: 1px solid #949494;
}

.grid-sales-inner-parent-two p {
  color: #949494;
}

.plus-img-sale {
  border: 1px solid #949494;
  padding: 0.4rem 0rem;
  text-align: end;
}

.py-box-content {
  background: var(--Antique-White);
  border-radius: 5px;
  padding: 1rem 1.5rem;
}

.py-box-content-head,
.py-box-content-para {
  font-size: 13px;
  margin-bottom: 0.1rem;
}

.py-box-content-head {
  font-weight: 400;
  color: #000;
}

.py-box-content-para {
  font-weight: 300;
  font-size: 13px;
  color: #707070;
}

.fagear-right-py {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid var(--Slate);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 0.4rem;
  background-color: #fff;
}

.fagear-right-py i {
  color: var(--Slate);
  font-size: 29px;
}

.flat-ship-inner {
  background: #fff;
  border: 1px solid #b9b9b9;
  border-radius: 3px;
}

.flat-ship-inner-head,
.flat-ship-inner-para {
  font-weight: 400;
  color: #7b7a7a;
}

.flat-ship-inner-head {
  font-size: 12px;
  margin-bottom: 0.3rem;
}

.flat-ship-inner-para {
  font-size: 14px;
  margin-bottom: 0;
}

.bg-gray {
  background: var(--Antique-White);
}

.order-detail-main {
  background: var(--Pale-Aqua);
  border-radius: 20px;
  margin-bottom: 100px;
}

.assign-acc h6 {
  font-size: 13px;
  margin: 0;
}

.assign-acc {
  border-radius: 5px;
}

.assign-acc p {
  background: var(--Slate);
  height: 100%;
  display: flex;
  margin: 0;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

.order-price-detail ul {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: white;
  border: 1px solid #b9b9b9;
}

.order-price-detail ul li p {
  font-size: 13px;
  display: flex;
  color: #000000;
  width: 30%;
  margin-left: auto;
  font-weight: 600;
  justify-content: flex-end;
  padding: 0px 10px;
  margin-bottom: 0;
}

.order-price-detail ul li p span {
  font-weight: 400;
  color: #7b7a7a;
  margin-left: 30px;
  width: 110px;
  text-align: end;
  border-left: 1px solid;
  padding: 0 10px;
}

.order-price-detail ul li {
  border-bottom: 1px solid #b9b9b9;
  display: block;
  width: 100%;
}

.order-price-detail ul li:last-child {
  border-bottom: 0px solid #b9b9b9;
}

.assign-btn a {
  background: var(--Admin-gradiant);
  padding: 10px 30px;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 50px;
}

.assign-btn a:last-child {
  background: red;
}

.history-table table {
  width: 100%;
}

.history-table table tr th {
  background: var(--Slate);
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 0px;
}

.history-table table tr {
  text-align: center;
}

.history-table h3 {
  color: #949494;
  font-size: 13px;
}

.history-table h3 i {
  color: var(--Slate);
}

.history-table table tr td {
  border: 1px solid #707070;
  color: #646464;
  font-size: 13px;
  padding: 5px 0px;
}

.addorder-history h3 {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin-bottom: 0;
}

.status-select {
  width: 100%;
  border: 1px solid #b9b9b9;
  padding: 10px 9px;
  color: #434343;
}

.addorder-history .form-group {
  width: 85%;
  margin-left: auto;
}

textarea.status-select {
  height: 189px;
}

.addorder-history .form-group label {
  width: 230px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  margin-right: 20px;
  font-size: 13px;
}

.button-switch {
  font-size: 1em;
  height: 1.875em;
  margin-bottom: 0;
  position: relative;
  width: 4.5em;
}

.button-switch .lbl-off,
.button-switch .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 0.5em;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
}

.button-switch .lbl-off {
  right: 0.4375em;
}

.button-switch .lbl-on {
  color: #fefefe;
  opacity: 0;
  left: 0.4375em;
}

.button-switch .switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0;
}

.button-switch .switch:before,
.button-switch .switch:after {
  content: "";
  font-size: 1em;
  position: absolute;
}

.button-switch .switch:before {
  border-radius: 1.25em;
  background: white;
  height: 1.875em;
  left: -0.25em;
  top: -0.1875em;
  transition: background-color 0.25s ease-out 0.1s;
  width: 4.5em;
}

.button-switch .switch:after {
  box-shadow: 0 0.0625em 0.375em 0 #666;
  border-radius: 50%;
  background: #bfbfbf;
  height: 1.4em;
  transform: translate(0, 0);
  transition: transform 0.25s ease-out 0.1s;
  width: 1.4em;
}

.button-switch .switch:checked:after {
  transform: translate(2.5em, 0);
}

.button-switch .switch:checked ~ .lbl-off {
  opacity: 0;
}

.button-switch .switch:checked ~ .lbl-on {
  opacity: 1;
}

.button-switch .switch#switch-orange:checked:before {
  background: #e67e22;
}

.button-switch .switch#switch-blue:checked:before {
  background: #3498db;
}

.logoutmodal.pay-address-modal .modal-dialog {
  max-width: 39%;
}

.logoutmodal.pay-address-modal .form-group label {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  color: #4d4d4d;
}

.logoutmodal.pay-address-modal .form-group {
  text-align: left;
}

.logoutmodal.pay-address-modal .form-control {
  background: #d8d8d8;
  border-radius: 0;
  border: 1px solid #707070;
}

.logoutmodal.pay-address-modal textarea.form-control {
  height: 100px;
}

.logoutmodal.pay-address-modal .modal-title {
  color: #4d4d4d;
  font-size: 22px;
}

.orderinvoicemodal .modal-dialog.modal-dialog-centered {
  max-width: 96%;
}

.close-mod {
  cursor: pointer;
  background: red;
  border-radius: 20px;
  font-size: 24px;
  color: white;
  padding: 3px 7px;
  position: absolute;
  right: 4px;
  top: 5px;
}

.invoiceorder h1 {
  text-align: left;
  font-size: 44px;
  color: #a1a1a1;
  font-weight: 500;
  padding-bottom: 30px;
}

.orderinvoicemodal .modal-header {
  border: none;
}

.orderinvoicemodal .modal-content {
  background: var(--Pale-Aqua);
  padding: 0 3rem;
}

.invoice-detail {
  background: white;
  border-radius: 10px;
  text-align: left;
  padding: 14px 20px;
  border: 1px solid #087bca;
}

.invoice-detail p {
  font-size: 30px;
}

.invoice-detail span {
  font-size: 25px;
  color: #acacac;
}

/* Refferal form */
section.Refferalsec {
  background: var(--Pale-Aqua);
  margin: 2rem 3rem;
  padding: 2rem 3rem;
  border-radius: 20px;
}

.testName p {
  margin: 0;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

section.Refferalsec tbody tr td {
  color: var(--Slate);
}

.refftitle.Attention p {
  color: var(--Slate);
}

section.Refferalsec tbody tr td,
section.Refferalsec tbody tr th {
  color: var(--Slate) !important;
  font-weight: 400 !important;
}

.testName {
  background: var(--Slate);
  padding: 1rem 2rem;
  border-radius: 20px;
}

.refftitle h1 {
  color: var(--Slate);
  font-size: 32px;
  font-weight: 500;
}

.refftitle .form-group label {
  min-width: 140px;
  display: flex;
  align-items: center;
  color: var(--Slate);
}

.refftitle ul li {
  color: var(--Slate);
}

.genderfunction span {
  background: white;
  margin: 0px 13px;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid var(--Slate);
  color: var(--Slate);
  font-size: 20px;
  font-weight: 500;
}

.test-instruction p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.test-instruction span {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin-right: 10px;
}

.optimaStatis {
  border: 2px solid var(--Slate);
  border-radius: 16px;
}

.optimaStatis span {
  padding: 10px 20px;
  display: block;
  width: 100%;
  font-size: 20px;
  background: var(--Slate);
  color: white;
  font-weight: 500;
  border-radius: 10px 10px 0px 0px;
}

.optimaStatis p {
  padding: 30px 20px !important;
  font-size: 16px;
  color: var(--Slate);
  margin: 0 auto;
}

thead.thead-dark tr th {
  background: var(--Slate);
  border: none;
  color: white;
  font-weight: 500;
}

table.table.table-bordered.table-hover tbody tr th,
table.table.table-bordered.table-hover tbody tr td {
  background: none;
  font-weight: 500;
}

table.table.table-bordered.table-hover {
  border: 1px solid var(--Slate);
  border-radius: 30px;
}

.refftitle.Collection label {
  width: 300px;
}

.genderfunction span:first-child {
  margin: 0;
}

.refftitle.Attention ul li {
  list-style: disc;
  font-weight: 500;
}

span.active-gender {
  background: var(--Slate);
  color: white;
}

.invoiceorder .py-box-content {
  background: #ffffff;
  text-align: left;
}

.tabhead .tabhead ul {
  display: grid;
  grid-template-columns: 50% 18% 16% 16%;
  background: var(--Slate);
  border-radius: 10px 10px 0px 0px;

  color: white;
  font-weight: 600;
}

.tabhead ul {
  display: grid;
  grid-template-columns: 50% 18% 16% 16%;
  background: var(--Slate);
  border-radius: 10px 10px 0px 0px;

  color: white;
  font-weight: 600;
}

.tabbody ul {
  display: grid;
  grid-template-columns: 50% 18% 16% 16%;
  background: white;
  border: 1px solid #b9b9b9;
}

.tabbody ul li {
  border-left: 1px solid #b9b9b9;
  padding: 6px 0;
}

.tabbody:last-child ul li:nth-child(2),
.tabbody:last-child ul li:nth-child(3) {
  border: none;
}

.tabbody:nth-child(3) ul li:nth-child(2),
.tabbody:nth-child(3) ul li:nth-child(3) {
  border: none;
}

.tabbody:nth-child(4) ul li:nth-child(2),
.tabbody:nth-child(4) ul li:nth-child(3) {
  border: none;
}

.tabhead ul li {
  border-left: 1px solid #b9b9b9;
  padding: 7px 0px;
}

.tabbody:last-child ul {
  border-radius: 0px 0px 10px 10px;
}

form.productadminform .form-txt input {
  padding: 10px 10px;
  height: 30px;
  width: 18px;
  accent-color: #1382ce;
}

form.productadminform .form-txt p {
  color: var(--Slate);
}

.dashboards.product-admin .blood-test-row ul {
  grid-template-columns: 40% 40% 20%;
}

.addNcut .form-txt p {
  color: var(--Slate);
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}

.bioselector.biooadd ul {
  display: flex;
  flex-flow: wrap;
  gap: 9px;
}

.bioselector.biooadd ul li {
  background: var(--Slate);
  padding: 10px 10px;
  border-radius: 6px;
  color: white;
  font-size: 13px;
}

.bioaddremove {
  display: flex;
  flex-flow: wrap;
  gap: 9px;
}

/* .admin-logout button {
  background: white;
  padding: 10px 40px;
  border-radius: 10px;
  color: #2e96d7;
} */

.admin-menus ul li.active {
  background: white;
  border-radius: 10px 0px 0px 10px !important;
  align-items: center !important;
}

.admin-menus ul li.active a {
  color: var(--Slate) !important;
  font-weight: 600;
}

.admin-menus ul li.active img {
  filter: grayscale(60) brightness(0.5) invert(6);
  margin: 0;
  padding: 0;
}

.sidebaradmin .filepond--image-preview-overlay svg {
  opacity: 0;
}

.signdeclinebtn button {
  background: transparent;
  border: 1px solid var(--dark-Pale-Aqua);
  /* padding: 9px 20px; */
  font-size: 13px;
  font-weight: 500;
  color: var(--dark-Pale-Aqua);
  margin-right: 20px;
  border-radius: 50px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.signdeclinebtn button.active {
  background-color: var(--dark-Pale-Aqua);
  color: white;
}

.signdeclinebtn {
  align-items: center;
  display: flex;
}

/* Refferal form */
.actve {
  background: #d6e9e9;
  color: #616161 !important;
  border: 1px solid #61616178;
  width: 110px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.actve:hover {
  background: green !important;
  color: white !important;
}

.dash-prac-status-check {
  background: green !important;
  color: white !important;
}

.dash-prac-status-uncheck {
  background: red !important;
  color: white !important;
}

.actve.Inact:hover {
  background: red !important;
}

.logoutmodal.delet-modale.modal .modal-content .close-mod {
  right: -11px;
  top: -26px;
}

/*  */
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: var(--dark-Pale-Aqua) !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--Slate) black !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

li.set-color-icn i {
  color: var(--Slate);
  cursor: pointer;
}

/*  */

/* blogs pages start */
.dashboards.product-admin .practionar-request.Blogstable ul.order-list {
  grid-template-columns: 15% 55% 15% 15%;
}

.dashboards.product-admin .practionar-request.Blogstable ul.sec-order-list {
  grid-template-columns: 15% 55% 15% 15%;
}

.dashboards.product-admin ul.order-list.order-list-2 {
  grid-template-columns: 15% 40% 15% 15% 15% !important;
}

.dashboards.product-admin ul.sec-order-list.order-list-2 {
  grid-template-columns: 15% 40% 15% 15% 15% !important;
}

.AddBlogsinput {
  width: 100%;
  /* background-color: var(--Pale-Aqua);
  border: 1px solid #d2d2d2; */
  padding: 5px;
  border-radius: 5px;
}

.bg-PaleAqua {
  background: var(--Pale-Aqua);
}

.previewCheckBox {
  background: #e1e1e1;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 10px 20px;
  /* width: 28%; */
}

.imgUploadSide {
  margin-top: 10px;
}

.admin-menus ul li img {
  width: 19px;
  height: 19px;
  object-fit: contain;
}

.selet-biom {
  background: #7a9ea0;
  padding: 1rem 1rem !important;
  border-radius: 7px;
  color: white;
  font-size: 13px;
}

.dashboards.Buildownadmin {
  overflow-x: initial;
}

.dashboards.product-admin {
  overflow-x: inherit;
}

.admin-menus .dropdown {
  display: flex;
}

.admin-menus .dropdown-item:hover,
.dropdown-item:focus {
  color: white !important;
  background-color: initial !important;
}

a.active.dropdown-item:hover {
  background-color: white !important;
  color: black !important;
}

.admin-menus .show.dropdown {
  display: grid;
  width: 100%;
}

.logoutimg {
  background: var(--Slate);
  border-radius: 18px 18px 0px 0;
  padding: 1rem 0rem;
}

.logoutimg img {
  width: 110px;
}

/* biomarker edit modal */
.readonlybio {
  padding: 1rem 2rem;
  overflow-y: scroll;
  height: 500px;
}

.readonlybio p {
  color: var(--Slate);
  font-size: 20px;
  font-weight: 500;
  margin: 3px;
  border-bottom: 1px solid;
}

.readonlybio div {
  text-align: left;
}

.readonlybiomarkersmodal .modal-dialog {
  max-width: 60%;
  height: 100%;
  width: 60% !important;
}

/* .readonlybiomarkersmodal .modal-content {

} */
.readonlybiomarkersmodal h2 {
  font-size: 34px;
  color: var(--Slate);
  font-weight: 500;
  padding-top: 30px;
}

.readonlybiomarkersmodal .modal-dialog {
  display: flex;
  align-items: center;
}

.bio-marker-modal .modal-body {
  padding: 0px 18px 28px !important;
}

.biomarkerlivepreview .btn-cart-own {
  flex-flow: wrap;
}

.addproduct-tabs .productadminform .css-1mzc8 {
  width: 100% !important;
}

.addproduct-tabs .productadminform label {
  display: block;
}

section.admin-side-side.operatosadmin .practionar-request ul.sec-order-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.biomarkerlivepreview .cart-own-learn-flex .add-to-cart-own {
  font-size: 13px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.addNcut .form-txt {
  padding: 15px 2px 0px;
}

form.productadminform h4 {
  font-size: 18px;
  font-weight: 700;
}

ul.sec-order-list li a {
  font-size: 13px;
}

ul.order-list li a {
  font-size: 13px;
}

.addproduct-tabs .form-txt p {
  font-size: 13px;
  margin-bottom: 0;
}

.latest-order-box-parent ul.sec-order-list li a {
  font-size: 13px;
}

.skurow .form-group:last-child {
  margin: 0;
}

.biomarkerbtn.long-tx button {
  width: 230px;
}

.biomarkerbtn .long-tx {
  width: 230px;
}

ul.MuiList-root .css-10hburv-MuiTypography-root {
  font-size: 13px;
}

.practionar-request.customer ul.sec-order-list li img {
  width: 40px;
  height: 40px;
}

.testall ul.sec-order-list li a i {
  color: #4f6367;
}

/* ------------------add test------------------ */
.content-main-pareny-customer-add-test {
  display: grid;
  grid-template-columns: 4% 96%;
  width: 85%;
}

.backchev-customer-add-test {
  color: var(--Slate);
}

.customer-add-test-input-label-parent {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
}

.right-first-box-customer-add-test-header {
  border-radius: 15px 15px 0 0;
}

.right-first-box-customer-add-test-header {
  background-color: var(--Slate);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.customer-add-test-input-label-parent label,
.customer-add-test-input-label-parent input,
.customer-add-test-input-label-parent textarea {
  font-size: 13px;
  font-weight: 400;
  margin: initial !important;
}

.customer-add-test-input-label-parent label {
  color: #5d5d5d;
}

.body-right-first-box-customer-add-test-header {
  background-color: var(--Pale-Aqua);
  border-radius: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.customer-add-test-input-label-parent input,
.customer-add-test-input-label-parent textarea {
  border-radius: 5px;
  border: 1px solid #707070;
}

.customer-add-test-input-label-parent input::placeholder,
.customer-add-test-input-label-parent textarea::placeholder {
  color: #0000006b;
}

.customer-add-test-input-label-parent textarea {
  height: 50px;
  padding: 0.5rem 1rem;
}

.customer-add-test-input-label-parent input {
  height: 40px;
  padding: 0 1rem;
}

.gapp-right-customer-right-add-test {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.update-btn-customer-add-test {
  width: 130px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Slate);
  color: #fff;
  border-radius: 22px;
  font-size: 13px;
}

.add-test-btn {
  margin-top: 1.5rem;
}

.second-box-customer-add-test {
  background-color: var(--Pale-Aqua);
  border-radius: 15px;
}

.second-box-select {
  width: 75%;
  margin: 0 auto 1.2rem auto;
}

.second-box-select select {
  width: 100%;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #707070;
  padding-left: 1rem;
  padding-right: 1rem;
}

.second-box-select select option {
  /* background-color: #000;
  color: #fff; */
}

.customer-add-test-input-label-parent select {
  height: 40px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 0.8rem;
}

.second-box-heading {
  margin-bottom: 0.6rem;
}

.mid-heading-color-green {
  color: #86ef1d !important;
}

.mid-heading-color-red {
  color: #ee1818 !important;
}

.mid-heading-level-pad {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.second-box-customer-add-test {
  padding: 1.5rem 1rem;
  margin-top: 2rem;
}

.para-second-box p {
  font-size: 13px;
  color: #797f82;
}

.para-second-box {
  text-align: center;
  width: 50%;
  margin: 0.8rem auto 0 auto;
}

.range-of-second-box .css-1aznpnh-MuiSlider-root {
  margin-bottom: 0 !important;
}

.red-res-pos-second-box-admin-cust {
  top: 0.55rem;
}

.range-of-second-box .red-res2 {
  width: 35%;
}

.range-of-second-box .MuiBox-root.css-1z13ce1 {
  display: flex;
}

.mid-heading-second-box {
  margin-bottom: 0.5rem;
}

/* .red-res1 {
  background: red;
  width: 33%;
  height: 9px;
  position: absolute;
  top: 0px;
  border-radius: 10px;
  z-index: 0;
} */

.second-box-label-input input,
.second-box-label-input label {
  font-size: 12px;
  width: 45%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-box-label-input label {
  gap: 0.3rem;
  background-color: #b5e1ff;
  color: #5d5d5d;
  border: 1px solid #707070;
  border-radius: 5px;
}

.second-box-label-input label span i {
  font-size: 9px;
}

.second-box-label-input input {
  padding: 0 0.5rem;
  outline: none;
  border-radius: 5px;
  color: #5d5d5d;
  border: 1px solid #707070;
}

.second-box-label-input {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.second-box-label-input-parent {
  display: flex;
  justify-content: space-between;
}

.result-second-box-parent {
  width: 25%;
  margin: 1rem auto 0 auto;
}

.save-test-btn {
  margin-top: 1.5rem;
}

/* ------------------add test------------------ */
/* add new testpanel sec */
.biomarkers-added-new {
  background: #faf9f7;
  border: 1px solid #d2d2d2;
  padding: 1rem 1rem;
  width: 60%;
  border-radius: 3px;
  width: 100%;
  margin-top: 15px;
}

.biomarkers-added-new h4 {
  color: var(--Slate);
  font-size: 13px;
  font-weight: 700;
}

.bio-proadded p {
  margin: 0;
  background: #7a9ea0;
  width: 130px;
  font-size: 13px;
  border-radius: 50px;
  height: 32px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 9px !important;
  display: block;
  line-height: 23px;
}

.bio-proadded {
  display: flex;
  flex-flow: wrap;
  gap: 8px;
}

span.pract-count {
  position: absolute;
  right: 17px;
  width: 17px;
  height: 17px;
  background: #4f6367;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
}

section.admin-side-side.table-boxes .signdeclinebtn button span {
  width: 20px;
  height: 20px;
  background: #4f6367;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.admin-side-side.table-boxes .signdeclinebtn button {
  width: 150px;
}

span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-nnid7-MuiSlider-valueLabel {
  display: none;
}

.dashboards.product-admin .FaqInstruction .blood-test-row ul {
  grid-template-columns: 10% 35% 35% 20%;
}

.FaqInstruction .blood-test-row ul li:nth-child(3) {
  text-align: start;
  padding-right: 31px;
  justify-content: flex-start;
}

.FaqInstruction .blood-test-row ul li:last-child {
  text-align: end;
  padding-right: 31px;
  justify-content: end;
}

.no-test-des {
  text-align: center;
  color: black;
  background: var(--Pale-Aqua);
  font-size: 1rem;
  padding: 10px 0;
}

.rangslidetest {
  width: 100%;
  height: 9px;
  background: rgb(238, 24, 24);
  /* background: linear-gradient(90deg, rgba(238,24,24,1) 0%, rgba(31,194,0,1) 40%, rgba(31,194,0,1) 60%, rgba(238,24,24,1) 100%); */
  border-radius: 50px;
  position: relative;
}

.rangslidetest span {
  background: white;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  top: -4px;
  box-shadow: 0 0 6px black;
}

.admin-logout.admin-all-add-btn button,
.admin-logout.admin-all-add-btn a {
  width: 110px;
}

.orderdetail-dropdown {
  padding: 5px;
  border-radius: 5px;
  outline: none;
  color: var(--Slate);
}

.prnt-fi-row-detail-me {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 22% 52% 22%;
  background: var(--Slate);
  margin: 22px 0 0;
  padding: 12px 0;
  border-radius: 4px;
}

.prnt-fi-row-txt-me h4 {
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.prnt-sec-row-detail-me {
  display: grid;
  grid-template-columns: 23.3% 53.3% 23.3%;
  border-radius: 6px;
}

.prnt-sec-row-txt-me {
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 12px 0;
  border: 1px solid #949494;
}

.prnt-sec-row-txt-me p {
  margin: 0;
}

/* AU CODE - BIOMARKERDOC PAGE (BIOMARKERS-API)*/

.object-viewer {
  background-color: #000;
  /* background: var(--Admin-gradiant); */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  /* max-width: 600px; */
  overflow-x: auto;
  position: relative;
}

.object-viewer .copy-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.object-viewer .copy-button .icon {
  color: #fff;
  font-size: 24px;
}

/* .object-viewer pre {
  color: white;
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
} */

.object-viewer pre {
  white-space: pre-wrap;
  font-family: "Courier New", Courier, monospace;
  color: #ddd;
  /* Light gray text color on black background */
}

.object-viewer .key {
  color: #60a0ff;
  /* Light blue color for keys */
}

.object-viewer .string-value {
  color: #98c379;
  /* Light green color for string values */
}

.object-viewer .number-value {
  color: #e06c75;
  /* Light red color for numeric values */
}

.object-viewer .boolean-value {
  color: #c678dd;
  /* Light purple color for boolean values */
}

/* Recom Admin  */
.recom-modal .form-group select {
  width: 100%;
  border: none;
  padding: 6px 5px;
  border-radius: 8px;
  font-size: 16px;
}

.recom-modal form {
  width: 95%;
  margin: 0 auto;
}

.recom-modal .modal-dialog {
  top: 0;
  transform: translatey(10px) !important;
}

.logoutmodal.recom-modal .modal-dialog {
  width: 60%;
}

.logoutmodal.recom-modal .form-group label {
  color: var(--Slate);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.history-form h4 {
  color: var(--Slate);
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.logoutmodal.recom-modal .avatar-upload {
  margin: 0;
}

.logoutmodal.recom-modal .avatar-preview {
  border: 9px !important;
  border-radius: 7px !important;
  background: white;
}

.logoutmodal.recom-modal .avatar-upload .avatar-preview > div {
  border-radius: 0;
}

.logoutmodal.recom-modal .form-group label span {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.icon-pr-fr img {
  height: 36px;
  width: 36px;
  padding-bottom: 0px;
}

.customerrecom {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 16px;
  width: 100%;
}

.customerrecom button {
  font-size: 13px;
  margin-bottom: 9px;
  height: 45px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid var(--Slate);
}

.customerrecom button.active {
  background: var(--Slate);
  color: white;
}

/* BLOG PAGE */
.practionar-request .blog-order-box {
  padding: 25px 0;
}

.blog-second-order-box {
  height: 69.5px !important;
}

.titleAddBlogs input {
  font-size: 16px;
  padding: 5px 15px;
  font-family: "Poppins";
  background-color: #e1e1e1;
  border: 1px solid #d2d2d2;
}

.titleAddBlogs input:focus-visible {
  outline: none !important;
}

.AddBlogsinput {
  background-color: #e1e1e1 !important;
  border: 1px solid #d2d2d2 !important;
  padding: 5px 15px;
}

.AddBlogsinput:focus-visible {
  outline: none !important;
}

.select-add-blog-biomarker .css-1fdsijx-ValueContainer {
  height: 35px;
}

.select-add-blog-biomarker h4 {
  padding-bottom: 0.5rem;
}

.previewCheckBox .preveiwCheck {
  margin-bottom: 0 !important;

  color: #717475;
  border: 1px solid #d2d2d2;
}

.previewCheckBox label {
  margin-bottom: 0 !important;
  font-size: 14px;
}

.blog-user-pics-detail {
  background-color: #e1e1e1;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin-top: 12px;
}

.visibility-opts button:hover {
  cursor: pointer;
}

.visibility-opts {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
  display: flex;
  justify-content: space-around;
}

.featured-img {
  width: 90%;
  background-color: #4f6367;
  margin: 0 auto;
  border-radius: 6px;
}

.featured-img h6 {
  padding: 14px;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 300;
  color: #ffffff;
}

.img-icon-uploader {
  width: fit-content;
}

.feature-img-main p {
  width: fit-content;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  margin: 0 !important;
}

.feature-img-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding-top: 10px;
}

.feature-img-main-outer {
  width: 90%;
  margin: 0 auto;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: 5px;
}

/* custom radio */
.blog-categories .form-blog {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  /* height: 100vh;
	width: 100%; */
}

.blog-categories .form-row {
  background: #ffffff;
  border-radius: 5px;
  padding: 8px 12px;
  margin: 10px;
  overflow: hidden;
  position: relative;
}

.blog-categories .form-input {
  -webkit-appearance: none;
  appearance: none;
}

.blog-categories .form-input::before {
  background-color: #e1e1e1;
  content: "";
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.blog-categories .form-input:checked::before {
  background: #4f6367;
}

/* .blog-categories .form-input::after {
	content: '';
	cursor: pointer;
	height: 15px;
	border-radius: 50px;
	border: 4px solid #21209c;
	left: 10%;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 15px;
} */

.blog-categories .form-input:checked::after {
  border: 4px solid #ffffff;
}

.blog-categories .form-label {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  margin-left: 0;
  margin-bottom: 0 !important;
  z-index: 1;
  /*position: absolute; This is an alternative option */
}

.blog-categories .form-input:checked ~ .form-label {
  color: #ffffff !important;
  user-select: none !important;
}

.blogger-name {
  display: flex;
  justify-content: center;
}

.blogger-name input {
  font-size: 15px;
  font: 300;
  width: 90%;
  padding: 6px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
}

.blogger-name input:focus-visible {
  outline: none !important;
}

.blogger-description {
  width: 90%;
  margin: 0 auto;
}

.blogger-description textarea {
  font-size: 15px;
  font: 300;
  width: 100%;
  padding: 6px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  height: 100px;
}

.blogger-description textarea:focus-visible {
  outline: none !important;
}

.save-draft-btn {
  border: 1px solid #d2d2d2;
  background-color: #e1e1e1;
  color: #717475;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
  border-radius: 5px;
  height: 43px;
  width: 100px;
}

.publish-btn {
  border: 1px solid #d2d2d2;
  background-color: #414040;
  color: #ffffff;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
  border-radius: 5px;
  height: 43px;
  width: 100px;
}

.edit-blog-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-img-author {
  width: 85%;
  border-radius: 25px;
}

.blog-preview-detail-main {
  background-color: #e1e1e1;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  padding: 20px;
}

.preview-para {
  font: 17px !important;
  font-family: "Poppins";
  font-weight: 600;
}

.blog-preview-detail-inner {
  display: flex;
  flex-direction: column;
}

.pewview-para-detail {
  font-size: 13px !important;
  font-family: "Poppins";
}

.blogs-tab-specific .ul.nav.nav-tabs {
  font-size: 16px;
  font-family: "Poppins";
}

.blogs-tab-specific .nav-tabs .nav-link.active {
  font-weight: 700 !important;
}

.blogs-tab-specific .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none !important;
}

.blogs-tab-specific .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none !important;
  outline: none !important;
}

.blogs-tab-specific span {
  color: #4f6367;
  font-size: 16px;
  font-family: "Poppins";
}

.blogs-tab-specific .nav-item .nav-link {
  border: none !important;
  outline: none !important;
  padding-left: 0px !important;
}

/* custom radio */
.ContentAddBlog .ck-editor__editable_inline,
.ql-editor {
  min-height: 400px;
}

.ql-editor {
  border: none;
}

.addtestQuill .ql-editor {
  min-height: 200px;
}

.ck.ck-powered-by {
  display: none !important;
}

/* .ContentAddBlog .ck-powered-by {
  display: none !important;
  opacity: none !important;

} */
/* .ContentAddBlog .ck ,.ck-balloon-panel, .ck-balloon-panel_position_border-side_right ,.ck-powered-by-balloon {
  display: none !important;

} */
/* BLOG PAGE */
/* NEW CUSTOMER REPORT */
.new-cust-report .nav-link {
  color: #7a9ea0;
  border: 1px solid #7a9ea0;
  font-weight: 500 !important;
  padding: 5px 10px;
  border-radius: 10px;
}

.new-cust-active {
  background-color: #7a9ea0;
  color: #ffffff;
}

.cust-report-head {
  background: #4f6367;
  padding: 1rem 2rem;
  border-radius: 15px 15px 0 0;
}

.cust-report-detail-main {
  padding: 1rem 2rem !important;
}

.cust-report-table {
  width: 80%;
}

.new-cust-report ul.nav.nav-tabs {
  border: none !important;
  gap: 25px;
}

.sex-btns-specific {
  border: 1px solid #707070;
  height: 35px;
  width: 90px;
  border-radius: 5px;
  font-size: 13px;
}

.sex-btn-active {
  background-color: #7a9ea0;
  color: #ffff;
}

.sex-btns {
  display: flex;
  gap: 15px;
}

.upt-btn-report-handler {
  display: flex;
  justify-content: end;
}

button.upt-btn-report {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  background-color: #7a9ea0;
  padding: 5px 30px;
  border-radius: 5px;
}

.report-second-box-select {
  width: 90% !important;
}

.upt-btn-report-handler button.add-test-btn {
  font-weight: 600 !important;
}

.new-cust-report .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #7a9ea0 !important;
  color: #ffffff !important;
  padding: 5px 10px;
}

.nav-link.active span.cust-report-stat {
  color: white !important;
}

.viewmore-btn-healthhub {
  margin-top: 5rem;
}

#menuToggle {
  display: none;
}

button.btn-shifting-specific {
  border: 1px solid #7a9ea0;
  padding: 6px 25px;
  color: #7a9ea0;
  border-radius: 30px;
  font-size: 13px;
}

.btn-shifting-tab {
  display: flex;
  gap: 15px;
}

button.btn-shifting-specific.active {
  background-color: #7a9ea0;
  color: #ffffff;
}

.equal-height-3 {
}

.equal-height {
  padding-top: 2.5vh !important;
}

.equal-height-2 {
  padding-top: 3vh;
}

.cust-vital-report-height {
  padding-top: 1.5vh;
}

input.form-control {
  font-size: 13px;
}

.modal-body .css-19gyxg0-option {
  font-size: 13px !important;
}

.modal-body .css-wsp0cs-MultiValueGeneric {
  font-size: 13px !important;
}

.modal-body .css-1jqq78o-placeholder {
  font-size: 13px !important;
}

.recom-left-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.pract-notes-custaddtest {
  font-size: 13px;
  padding: 5px 10px;
  width: 100%;
  margin-top: 1rem;
  border-radius: 5px;
  height: 150px;
}

.custaddtest-lastbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.upload-ref-documents {
  width: 220px;
}

/* NEW CUSTOMER REPORT */

/*  */
.showentries {
  font-size: 13px;
  margin-top: 0.35rem;
}

.showentries label {
  margin: 0;
}

.second-box-customer-add-test h2 {
  font-size: 20px;
  text-align: center;
  color: var(--Slate);
}

.second-box-customer-add-test p {
  font-size: 13px;
  text-align: center;
}

.addtestNotes p {
  font-size: inherit;
  text-align: left;
}

.second-box-customer-add-test input {
  margin-bottom: 0.4rem;
  width: 35%;
  font-size: 13px;
  padding: 5px;
}

.filter-reset button {
  font-size: 13px !important;
}

ul.sec-order-list {
  font-size: 13px;
}

.biomarker-search-bar {
  justify-content: space-between;
  margin-top: 15px;
}

.admin-catg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-add-blog-biomarker .css-1bdxf3e-control {
  max-height: 1500px !important;
  height: initial !important;
  min-height: 35px !important;
}

.select-add-blog-biomarker .css-1fzv6uc-control {
  max-height: 1500px !important;
  height: initial !important;
  min-height: 35px !important;
}

/* subcriptions page */
.sub-graph {
  background: #ffffff;
  border-radius: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 2px 1px 16px 0px #00000059;
  margin-bottom: 20px;
  border: 1px solid #707070;
  text-align: end;
}

.subcription-table-data .practionar-request.customer ul.order-list {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.subcription-table-data .practionar-request.customer ul.sec-order-list {
  grid-template-columns: 20% 20% 20% 20% 18%;
}

.sub-graph select {
  color: #4f6367;
  margin-right: 2rem;
  width: 12rem;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 300;
}

.manage-subcription-table-data
  .practionar-request.customer
  ul.sec-order-list
  li
  .actve {
  width: 110px;
  gap: 5px;
  display: flex;
  align-items: center;
}

.search-admin-addtest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*  */

.practionar-request.customer.remove-sub ul.order-list {
  grid-template-columns: 20% 20% 20% 15% 25%;
}

.practionar-request.customer.remove-sub ul.sec-order-list {
  grid-template-columns: 20% 20% 20% 15% 25%;
  padding-right: 12px;
}

/* 0---- */
.admin-cust-request-manage-subs-btn {
  font-size: 12px;
  border: 1px solid #70707088;
  color: #616161;
  padding: 0.3rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.418);
}

/* 0---- */
/* File preview */
.right-customer-add-test .file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  width: 50%;
  margin: 0 auto;
  margin-top: 17px;
}
.right-customer-add-test .file-upload .file-select {
  display: block;
  border: 2px solid #b8d9d9;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.right-customer-add-test .file-upload .file-select .file-select-button {
  background: #b8d9d9;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.right-customer-add-test .file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
.right-customer-add-test .file-upload .file-select:hover {
  border-color: #4f6367;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.right-customer-add-test .file-upload .file-select:hover .file-select-button {
  background: #4f6367;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.right-customer-add-test .file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.right-customer-add-test .file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.right-customer-add-test .file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.right-customer-add-test .file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.right-customer-add-test .file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.right-customer-add-test
  .file-upload
  .file-select.file-select-disabled:hover
  .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.right-customer-add-test
  .file-upload
  .file-select.file-select-disabled:hover
  .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.inactiveRed {
  background-color: red;
  color: #fff !important;
}
.back-dash button {
  font-size: 18px !important;
  font-weight: 500;
  color: white;
  background: var(--Slate);
  width: 130px !important;

  height: 45px !important;
  border-radius: 50px;
}

/* File preview */

.recom-title-2 {
  font-size: 20px;
  color: var(--Slate);
}
.ref-document-head {
  display: flex;
  justify-content: center;
}
.upd-ref-document {
  padding-bottom: 10px;
}
.build-own-format {
  display: flex;
}
.build-down-main-head {
  width: 98.6%;
}
.range-of-second-box {
  margin-bottom: 1rem;
}
.pos-neg-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--Slate);
  text-wrap: nowrap;
}
.pos-neg-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .fade.logoutmodal.delet-modale.modal .modal-footer .color-set-modal {
    width: 100px;
  }
  .fade.logoutmodal.delet-modale.modal .modal-footer .delete-modal-btn {
    width: 100px;
  }
  .fade.logoutmodal.delet-modale.modal h1 {
    line-height: 1.5;
  }
  .modal-footer {
    margin-top: 10px;
  }
  .biomarkerbtn .long-tx {
    width: 310px;
  }
  .dashboards.product-admin .blood-test-row ul {
    grid-template-columns: 22% 60% 18%;
    padding: 5px 10px;
  }
  .Add-new-test.show > div {
    width: 440px;
  }
  .Add-new-test.show {
    overflow: auto;
  }
  .FaqInstruction .blood-test-row ul li:nth-child(3) {
    padding-right: 0px;
  }
  button.mb-5.biomarkeraddbtn {
    margin-top: 30px;
  }
  .build-own-format {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .addNcut .form-txt {
    align-items: center;
  }
  h2.label-title {
    margin-bottom: 0;
  }
  .fourth-grid .slick-slider button {
    display: none !important;
  }
  .feature-img-main {
    padding: 0;
  }
  .latest-order-box-parent {
    padding: 22px 18px 32px;
  }
  .practionar-request.customerlist ul.sec-order-list {
    grid-template-columns: 12% 14% 20% 8% 8% 12% 14% 12%;
  }
  .admin-logout button {
    padding: 7px 23px;
  }
  .practionar-request.customerlist ul.order-list {
    grid-template-columns: 12% 14% 20% 8% 8% 12% 14% 12%;
  }
  .blood-test-row > ul:nth-child(1) {
    padding-left: 10px;
  }
}
