:root {
  --header-item-height: 48px;
}

.fixedHeader {
  background: var(--Slate);
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  padding: 14px 0;

  .dropdown-menu.show {
    display: block;
    padding: 20px 10px;
  }

  @media only screen and (max-width: 1800px) {
    .logo a img {
      max-width: 139px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .dropdown-menu.show {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 1446px) {
    .logo a img {
      max-width: 119px;
    }
  }

  @media only screen and (max-width: 999px) {
    padding: 20px 0;
  }
}

.header {
  background: var(--Slate);
  width: 100%;
  z-index: 99;
  padding: 14px 0;

  .dropdown-menu.show {
    display: block;
    padding: 20px 10px;
  }

  @media only screen and (max-width: 1800px) {
    .logo a img {
      max-width: 139px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .dropdown-menu.show {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 1446px) {
    .logo a img {
      max-width: 119px;
    }
  }

  @media only screen and (max-width: 999px) {
    padding: 20px 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.search {
  position: relative;
  width: 40px;

  > div {
    display: flex;
    position: absolute;
    z-index: 9999;
    width: 240px;
    left: -200px;
    cursor: text;
  }

  input {
    width: 100%;
    border: none;
    background: white;
    border-radius: 7px;
    height: var(--header-item-height);
    padding: 0 30px 0 8px;
    font-size: 20px;
    font-weight: 400;
    font-family: "Poppins";
    color: black;
    transition: width 0.3s ease-in-out;
    cursor: text;

    &::placeholder {
      color: #0000006e;
    }
  }

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--Slate);
    cursor: pointer;
  }

  /* Adjust width dynamically based on screen size */
  @media only screen and (max-width: 1200px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 180px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
    input {
      font-size: 18px; /* Reduce font size for smaller screens */
      height: 38px; /* Slightly smaller height */
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 100px;
    input {
      font-size: 16px;
      height: 35px;
      padding: 0 25px 0 8px;
    }
  }
}

.desktopMenu {
  flex: 1;
  padding-left: 2em;
  min-width: 200px;
  align-items: center;

  a {
    &:hover {
      background-color: var(--Antique-White);
      color: white;

      @media only screen and (max-width: 1117px) {
        background: transparent;
        text-decoration: underline !important;
      }

      &:before {
        opacity: 1;
        bottom: -8px;
        left: -8px;
      }

      &:after {
        opacity: 1;
        top: -8px;
        right: -8px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 12px;
      left: 12px;
      width: 12px;
      height: 12px;
      border: 3px solid var(--Pale-Aqua);
      border-width: 0 0 3px 3px;
      opacity: 0;
      transition: all 0.3s;

      @media only screen and (max-width: 1117px) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 12px;
      right: 12px;
      width: 12px;
      height: 12px;
      border: 3px solid var(--Pale-Aqua);
      border-width: 3px 3px 0 0;
      opacity: 0;
      transition: all 0.3s;

      @media only screen and (max-width: 1117px) {
        display: none;
      }
    }
  }

  > ul > li > a:hover {
    background-color: transparent;
    text-decoration: underline;

    @media only screen and (min-width: 1118px) {
      background: var(--Antique-White);
      color: var(--Slate) !important;
      text-decoration: none;
    }
  }

  ul {
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .active {
      a {
        &:after {
          opacity: 1;
          top: -8px;
          right: -8px;
        }

        &:before {
          opacity: 1;
          bottom: -8px;
          left: -8px;
        }
      }

      > a {
        color: var(--Slate) !important;
        background: var(--Antique-White);
      }
    }

    li a {
      padding: 3px 11px;
      font-size: 1.2rem;
      font-family: "Poppins";
      font-weight: 500;
      text-decoration: none;
      color: var(--Antique-White);
      border-radius: 0;
      position: relative;

      @media only screen and (max-width: 1117px) {
        padding: 0px;
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    ul {
      gap: 18px;
    }
  }
  @media only screen and (max-width: 1600px) {
    ul {
      gap: 14px;

      li a {
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 1446px) {
    ul {
      gap: 12px;

      li a {
        font-size: 14px;
        padding: 4px 9px;
      }
    }
  }
  @media only screen and (max-width: 1118px) {
    ul {
      li {
        text-align: center;
        a {
          padding: 0px;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    ul {
      gap: 10px;
    }
  }
  @media only screen and (max-width: 999px) {
    ul {
      gap: 0px;
    }
  }
}

.buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;

  button#dropdown-basic {
    background: #087bca;
  }

  .dropdown {
    display: flex;
    align-items: center;
  }

  a.dropdown-item {
    button {
      background: #087bca;
      padding: 9px 26px;
      border-radius: 12px;
      font-size: 18px;
      font-weight: 500;
      color: #ffff;
      font-family: "Poppins";
      width: 100%;
      margin-top: 13px;

      @media only screen and (max-width: 1446px) {
        padding: 5px 30px;
        font-size: 15px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    a.login-btn {
      display: none;
    }
  }
}

.iconButton {
  border: 1px solid var(--Antique-White);
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-self: center;
  text-decoration: none;
  position: relative;
  height: var(--header-item-height);
  width: var(--header-item-height);
  cursor: pointer;

  span {
    position: absolute;
    right: -8px;
    top: -8px;
    background: var(--dark-Pale-Aqua);
    color: white;
    border-radius: 30px;
    font-size: 13px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin: auto;
  }
}
