@media only screen and (max-width: 1800px) {
  .equal-height-3 {
    padding-top: 2vh !important;
  }
  .left-top-grid h1 {
    font-size: 60px;
    letter-spacing: 0.5px;
    line-height: 69px;
    color: var(--Slate);
    font-weight: 700;
  }
  .top-left-para {
    padding-top: 14px;
    color: #303030;
    font-size: 20px;
  }
  .contact-top-form label {
    color: var(--Slate);
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 8px;
  }
  .contact-top-form input {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    padding: 11px 25px;
    height: 60px;
    width: 100%;
  }
  .grid-form-three input {
    font-size: 17px;
  }
  .contact-top-sec {
    margin-top: 80px;
    padding: 45px 10px;
    background-size: cover;
  }
  .contact-top-form input::placeholder {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #636363;
  }
  .msg-input textarea {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    resize: none;
    border: 1px solid #087bca;
    border-radius: 5px;
    padding: 11px 25px;
    width: 100%;
    min-height: 195px;
  }

  .msg-input textarea::placeholder {
    font-size: 18px;

    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #636363;
  }
  .cust-add-test-head {
    font-size: 18px;
  }
  .cust-report-head {
    background: #4f6367;
    padding: 1rem 2rem;
    border-radius: 15px 15px 0 0;
    font-size: 18px;
  }
  /* .sidebaradmin {
    overflow-y: scroll;
    overflow-x: hidden;
  } */
  .sidebaradmin {
    left: 0rem;
  }
  .admin-logout {
    margin-bottom: 10px;
    margin-top: 20px;
    bottom: 0px !important;
  }
  .dashboards header.Mean-header .logo a img {
    max-width: 139px;
  }
  .orderlist-form {
    width: 300px;
  }

  .filter-reset span {
    font-size: 13px;
  }

  .filter-reset p {
    font-size: 13px;
  }

  .orderlist-form label {
    font-size: 13px;
  }

  .order-apply button {
    font-size: 13px;
    font-weight: 600;
    padding: 7px 29px;
  }

  .order-lsit ul.order-list li a {
    font-size: 13px;
  }

  .order-lsit ul.sec-order-list li a {
    font-size: 13px;
  }
  .fade.logoutmodal.bio-marker-modal.modal.show .modal-dialog {
    max-width: 79%;
    width: 60%;
  }
  .cust-vital-report-height {
    padding-top: 0.2vh;
  }
}
@media only screen and (max-width: 1600px) {
  .practionar-request .sec-order-box {
    height: 45px;
  }
  .cust-vital-report-height {
    padding-top: 0.2vh;
  }
  .equal-height-3 {
    padding-top: 1.5vh !important;
  }
  .cust-add-test-head {
    font-size: 16px;
  }
  .customer-add-test-input-label-parent select {
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 0.8rem;
  }
  .second-box-heading h5 {
    font-size: 18px;
  }
  .second-box-select select {
    width: 100%;
    font-size: 12px;
    outline: none;
    cursor: pointer;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #707070;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .second-box-select select {
    width: 100%;
    font-size: 12px;
    outline: none;
    cursor: pointer;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #707070;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mid-heading-second-box h5 {
    font-size: 18px;
  }
  .para-second-box p {
    font-size: 12px;
    color: #797f82;
  }
  .cust-report-stat {
    font-size: 16px;
  }
  .cust-report-head {
    background: #4f6367;
    padding: 1rem 2rem;
    border-radius: 15px 15px 0 0;
    font-size: 16px;
  }
  .customer-add-test-input-label-parent label,
  .customer-add-test-input-label-parent input,
  .customer-add-test-input-label-parent textarea {
    font-size: 12px;
    font-weight: 400;
  }
  .customer-add-test-input-label-parent input {
    height: 35px;
    padding: 0 1rem;
  }
  .sex-btns-specific {
    border: 1px solid #707070;
    height: 32px;
    width: 90px;
    border-radius: 5px;
    font-size: 12px;
  }
  button.upt-btn-report {
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
    background-color: #7a9ea0;
    padding: 5px 25px;
    border-radius: 5px;
  }
  .formgroup.expiry-dt input {
    font-size: 11px;
    padding: 3px 6px;
  }
  .admin-logout.admin-all-add-btn button,
  .admin-logout.admin-all-add-btn a {
    width: 110px;
    padding: 0 0;
  }
  .back-dash a {
    font-size: 13px;
    padding: 8px 18px;
  }
  .biomarkerbtn a {
    font-size: 13px;
  }
  .sidebaradmin .filepond--root.filepond--hopper {
    height: 100px !important;
  }
  .readonlybio p {
    font-size: 17px;
  }

  .readonlybio span {
    font-size: 14px;
  }
  .admin-logout a {
    padding: 7px 0px;
    width: 107px;
  }

  .sidebaradmin .filepond--image-preview-wrapper {
    height: 100px;
    width: 100px;
  }
  .addproduct-tabs li.nav-item .nav-link {
    padding: 9px 16px;
  }
  .admin-logout button {
    padding: 7px 30px;
    font-size: 11px !important;
  }
  .print-btn a {
    font-size: 19px;
  }
  .sidebaradmin .filepond--wrapper {
    width: 100px !important;
  }

  .sidebaradmin .my-file-upload p {
    font-size: 18px;
    margin: 0;
  }

  .admin-menus ul li a {
    font-size: 13px;
  }
  .sidebaradmin {
    width: 280px;
    left: 4.5rem;
  }
  section.admin-side-side {
    width: calc(100% - 310px);
  }

  .hiadmin h2 {
    padding-bottom: 15px;
  }
  img.plus-bg {
    width: 34%;
  }
  .test-field {
    margin-bottom: 18px;
  }
  .test-field input {
    border-radius: 6px;
  }

  .fade.logoutmodal.delet-modale.modal .modal-footer a {
    height: 40px;
    width: 150px;
    font-size: 16px;
  }
  .two-lnk li {
    width: 120px;
  }

  .testall ul.sec-order-list li p {
    font-size: 13px;
  }

  /*  */
  .Patient-report-name {
    padding: 60px 8rem;
  }

  .Patient-report-name h1 {
    font-size: 40px;
  }

  .Patient-report-name h2 {
    font-size: 25px;
  }

  .Patient-report-name p {
    font-size: 18px;
  }

  .Patient-report-name img {
    right: 3rem;
    top: -80px;
    width: 45%;
  }

  .row.sum-row .Doc-name p {
    font-size: 20px;
  }

  .Doc-name img {
    width: 31%;
  }

  .inner-recom-box img {
    width: 70px;
  }
  .fertin {
    padding: 40px 50px;
  }

  .range-sld .css-1z13ce1 {
    width: 360px;
  }

  .title-test h2 {
    font-size: 22px;
  }

  .recom-right-box .inner-recom-box {
    height: 220px;
  }

  .inner-recom-box {
    width: 406px;
    height: 220px;
  }

  .biomarkers-add ul li {
    padding: 7px 13px;
    font-size: 14px;
  }

  .biomarkers-add ul {
    gap: 12px;
  }

  .addprice-sku input {
    height: 41px;
  }

  .biomarkerlivepreview .btn-cart-own button {
    padding: 8px 15px;
  }
  header.Mean-header .dropdown-menu.show {
    margin-top: 10px;
  }
  .logoutmodal.pay-address-modal .modal-dialog {
    max-width: 50%;
  }
  .logoutmodal.pay-address-modal .form-group label {
    font-size: 15px;
    margin-bottom: 7px;
  }

  .logoutmodal.pay-address-modal .biomarkerbtn {
    padding-bottom: 0;
  }

  .logoutmodal.pay-address-modal .form-control {
    height: 34px;
  }
  .logoutmodal.pay-address-modal .modal-dialog {
    top: 50%;
  }
  .admin-all-add-btn a {
    font-size: 11px !important;
  }
  .equal-heigt-addtest {
    padding-top: 2.5vh !important;
  }
}

@media only screen and (max-width: 1440px) {
  .build-down-main-head {
    width: 98.3%;
  }
  .admin-menus ul li a {
    font-size: 11px;
  }
  .equal-heigt-addtest {
    padding-top: 2vh !important;
  }
  .admin-logout a,
  .admin-logout button {
    height: 25px;
  }

  .orderlist-height {
    padding-top: 1.5vh !important;
  }
  .cust-vital-report-height {
    padding-top: 0;
  }
  .equal-height-3 {
    padding-top: 0.5vh !important;
  }
  .cust-add-test-head {
    font-size: 14px;
  }
  .customer-add-test-input-label-parent select {
    height: 40px;
    font-size: 11px;
    font-weight: 400;
    padding: 0 0.8rem;
  }
  .cust-report-stat {
    font-size: 14px;
  }
  .cust-report-head {
    background: #4f6367;
    padding: 0.8rem 2rem;
    border-radius: 15px 15px 0 0;
    font-size: 14px;
  }
  .box-label-input label {
    font-size: 11px;
    width: 45%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second-box-heading h5 {
    font-size: 16px;
  }
  .second-box-select select {
    width: 100%;
    font-size: 11px;
    outline: none;
    cursor: pointer;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #707070;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mid-heading-second-box h5 {
    font-size: 16px;
  }
  .para-second-box p {
    font-size: 11px;
    color: #797f82;
  }
  .customer-add-test-input-label-parent label,
  .customer-add-test-input-label-parent input,
  .customer-add-test-input-label-parent textarea {
    font-size: 11px;
    font-weight: 400;
  }
  .customer-add-test-input-label-parent input {
    height: 30px;
    padding: 0 1rem;
  }
  .sex-btns-specific {
    border: 1px solid #707070;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    font-size: 11px;
  }
  button.upt-btn-report {
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    background-color: #7a9ea0;
    padding: 5px 20px;
    border-radius: 5px;
  }
  /* .cust-gap{
  padding: 0 14rem 0 0;
} */
  .sec-order-box ul.sec-order-list .underline span {
    font-size: 10px;
  }
  .practionar-request.customer ul.sec-order-list li .actve {
    width: 80px;
    font-size: 11px;
    height: 25px;
  }
  .practionar-request.customer ul.sec-order-list li a {
    font-size: 10px;
  }
  .dashboards header.Mean-header .logo a img {
    max-width: 119px;
  }
  .sidebaradmin .filepond--root.filepond--hopper {
    height: 80px !important;
  }

  .sidebaradmin .filepond--wrapper {
    width: 80px !important;
  }
  .sidebaradmin .my-file-upload p {
    font-size: 14px;
    margin: 0;
  }
  .sidebaradmin .filepond--drop-label.filepond--drop-label label {
    padding: 0;
    font-size: 6px;
  }
  .admin-logout a {
    padding: 7px 0px;
    width: 107px;
  }

  .admin-menus ul li {
    margin-bottom: 15px;
  }

  .admin-menus button#dropdown-basic-button {
    font-size: 12px;
  }

  .hiadmin h2 {
    padding-bottom: 5px;
  }

  img.plus-bg {
    width: 42%;
  }

  .order-detail-img img {
    width: 50px;
    height: 50px;
  }

  ul.order-list li a {
    font-size: 13px;
  }

  ul.sec-order-list li a {
    font-size: 11px;
  }

  .row.graph-row-2 .col-lg-8 {
    margin: 0px 0px 10px;
  }
  .detail-page-test h1 {
    font-size: 35px;
  }

  .detail-page-test {
    padding: 30px 50px;
    border-radius: 20px;
  }

  .gender-customer label.partici-character {
    font-size: 16px;
  }

  .gender-customer input {
    width: 20px;
    height: 20px;
  }

  label.gender-label {
    font-size: 18px;
  }

  .test-field input {
    height: 40px;
  }
  .test-btn button {
    font-size: 18px;
    padding: 7px 40px;
  }
  .sidebaradmin {
    width: 250px;
  }
  section.admin-side-side {
    width: calc(100% - 280px);
  }
  .logoutmodal .modal-dialog {
    width: 550px;
  }
  .fade.logoutmodal.delet-modale.modal .modal-body {
    padding: 0;
  }

  .fade.logoutmodal.delet-modale.modal .modal-body img {
    width: 80px;
  }

  .fade.logoutmodal.delet-modale.modal .modal-footer {
    padding: 0;
  }
  section.admin-side-side.report-custom {
    padding-left: 2rem;
  }
  span.arrow-back {
    left: -5px;
  }

  span.arrow-back i {
    font-size: 24px;
  }
  .Patient-report-name {
    padding: 50px 7rem;
  }

  .Patient-report-name h1 {
    font-size: 35px;
  }

  .Patient-report-name h2 {
    font-size: 21px;
  }

  .Patient-report-name p {
    font-size: 16px;
  }

  .row.sum-row .Doc-name p {
    font-size: 17px;
  }

  .row.sum-row {
    padding: 20px 0;
  }

  .range-sld .css-1z13ce1 {
    width: 320px;
  }

  .result-history-btn a {
    font-size: 14px;
  }

  .fordate p {
    font-size: 14px;
    margin-bottom: 9px;
  }

  .fordate span {
    font-size: 14px;
    width: 89px;
    height: 25px;
  }

  .inner-recom-box {
    width: 362px;
    height: 230px;
  }

  .inner-recom-box a {
    font-size: 13px;
    padding: 5px 16px;
  }

  .recom-right-box .inner-recom-box {
    height: 230px;
  }

  .print-btn a {
    font-size: 17px;
  }
  .search-product input {
    width: 360px;
    padding: 11px 35px;
  }

  .search-product i {
    font-size: 18px;
    top: 42%;
  }

  .categoriesFilter select {
    padding: 11px 20px;
  }

  .showTable ul li a {
    font-size: 14px;
  }
  .row-fi-order-box {
    padding: 13px 0;
  }

  .body-data {
    padding: 5px 0;
  }

  .order-lsit ul.order-list li a {
    font-size: 11px;
  }

  .order-lsit ul.sec-order-list li a {
    font-size: 12px;
  }

  .orderlist-form label {
    font-size: 13px;
  }

  .orderlist-form input::placeholder {
    font-size: 12px;
  }

  .orderlist-form select {
    font-size: 12px;
  }

  .orderlist-form input {
    font-size: 12px;
  }

  .showentries select {
    font-size: 12px;
    width: 100px;
  }
  a.back-trans.border-btn {
    padding: 9px 17px;
  }

  a.back-trans.border-btn span {
    font-size: 11px;
    padding: 2px 5px;
    right: -10px;
    top: -12px;
  }
  .orderlist-form {
    width: 243px;
  }
  .testName p {
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  .refftitle h1 {
    font-size: 25px;
  }

  .refftitle .form-group label {
    font-size: 13px;
  }

  .genderfunction span {
    font-size: 15px;
  }

  .test-instruction p {
    font-size: 15px;
  }

  .optimaStatis span {
    font-size: 16px;
  }

  .optimaStatis p {
    width: 100%;
  }
  .logoutmodal.pay-address-modal .modal-content {
    padding: 0 10px;
  }

  .logoutmodal.pay-address-modal textarea.form-control {
    height: 80px;
  }
  .logoutmodal.pay-address-modal .biomarkerbtn a {
    font-size: 15px;
    padding: 5px 20px;
  }

  .logoutmodal.pay-address-modal .biomarkerbtn a {
    font-size: 15px;
    padding: 5px 20px;
  }

  .logoutmodal.pay-address-modal .modal-title {
    font-size: 18px;
  }

  .logoutmodal.pay-address-modal .modal-header {
    padding: 10px 0 3px 10px;
  }

  .logoutmodal.pay-address-modal .form-control {
    height: 29px;
  }
  img.side-img-hiadmin {
    right: 12rem;

    width: 25%;
  }
  .sidebaradmin .filepond--image-preview-wrapper {
    height: 80px;
    width: 80px !important;
  }

  .admin-menus ul li {
    padding: 2px 9px;
    align-items: baseline;
  }

  .admin-menus ul li img {
    width: 15px;
    height: 15px;
  }
  .biomarkerbtn a {
    padding: 5px 30px;
    font-size: 15px;
  }
  .showTable a.login-btn {
    padding: 5px 30px;
  }
  .admin-all-add-btn button {
    font-size: 11px !important;
  }
  .admin-all-add-btn a {
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 991px) {
  .admin-menus a.active.dropdown-item {
    width: 96%;
  }

  section.admin-side-side {
    width: calc(100% - 0px);
  }

  .sidebaradmin {
    height: 100vh;
    transition: all 0.5s ease-in-out;
    left: -251px;
    z-index: 9;
    bottom: 0;
  }

  .sidebaradmin.open {
    left: 0px; /* Sidebar visible */
  }
  /* HAMBURGER DAHSBOARD */
  .nav-ham {
    position: absolute;
    left: 0;
    top: 50%;
  }

  #menuToggle {
    display: block;
    position: absolute;
    top: 50%;
    right: -4.2rem;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    background: #8aaeb0;
    padding: 8px 17px;
    border-radius: 0px 15px 15px 0px;
  }

  /* #menuToggle:hover{
  background: #8aaeb0;
} */
  .sidebaradmin.open div#menuToggle {
    /* transform: rotate(180deg); */
    /* right: 0rem; */
    transition: all 0.5s ease-in-out;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-10px, -11px);
    background: white;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-9px, 11px);
  }
  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
  /* HAMBURGER DAHSBOARD */
}
@media only screen and (max-width: 767px) {
  section.admin-side-side.operatosadmin .form-group label {
    width: 50%;
    font-size: 13px !important;
    display: flex;
    align-items: center;
  }
  /* PRACTITIONER DASHBOARD */

  /* PRACTITIONER DASHBOARD */
  /* ADMIN DASHBOARD */
  .admin-front-page {
    padding: 25px 20px;
  }
  .hiadmin h1 {
    font-size: 20px;
    color: white;
    font-weight: 600;
  }
  .hiadmin h2 {
    font-size: 16px;
    font-weight: 200;
    color: white;
    padding-bottom: 10px;
  }
  .hiadmin p {
    font-size: 10px;
    color: white;
    font-weight: 400;
  }
  .order-detail-box {
    margin-bottom: 1rem;
  }
  .order-detail-content p {
    font-size: 11px;
  }
  .order-detail-content span {
    font-size: 12px;
  }
  .order-detail-content {
    text-align: center;
    padding-top: 5px;
  }
  .mob-fi-order-box {
    overflow-x: scroll;
  }

  .mob-fi-order-box div {
    width: 700px;
  }
  .graph-row-1 canvas {
    border: 2px solid #bbb5b5;
    margin: 0px;
    margin-bottom: 1rem;
    border-radius: 20px;
  }
  .row.graph-row-2 {
    padding: 0px 10px;
  }
  canvas {
    margin: 15px 0 16px 0px;
    padding: 5px 0px;
  }
  .row.graph-row-1 {
    padding: 20px 0px 10px;
    margin: 30px 0px 20px;
  }

  /* ADMIN DASHBOARD */
  /* ADMIN PRACTITIONER */
  .mob-pract-admin-details {
    overflow-x: scroll;
  }
  .mob-pract-admin-details div {
    width: 700px;
  }
  .practionar-request .admin-logout,
  .act-inact {
    width: 100% !important;
  }
  .act-inact {
    gap: 5px;
    padding: 0px 7px;
  }
  .act-inact button {
    padding: 7px 0;
  }

  .latest-order-box-parent {
    overflow-x: scroll;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .latest-order-box-parent div {
    width: 700px;
  }
  .admin-logout a,
  .admin-logout button {
    height: 25px;
  }
  .act-inact button {
    height: 25px;
  }
  .practionar-request .sec-order-box {
    height: 40px;
  }
  .practionar-request .fi-order-box {
    padding: 12px 0;
  }
  /* ADMIN PRACTITIONER */
  /* ADMIN EXTERNAL REPORT */
  .cust-report-table {
    width: 100%;
  }
  .new-cust-report ul.nav.nav-tabs {
    justify-content: space-between;
  }
  .cust-report-detail-main {
    padding: 1rem 1rem !important;
  }
  .second-box-label-input-parent {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    justify-content: center;
  }
  .second-box-label-input label {
    width: 100px;
  }
  .para-second-box {
    text-align: center;
    width: 100%;
  }
  .detail-page-test {
    padding: 20px 20px;
    border-radius: 20px;
  }
  .detail-page-test h1 {
    padding-bottom: 0;
  }
  .test-field {
    margin-bottom: 10px;
  }
  .test-field label {
    padding-bottom: 0;
  }
  .test-btn button {
    font-size: 13px;
  }
  .practionar-request.customer ul.sec-order-list li img {
    width: 30px;
    height: 30px;
  }
  .testall {
    overflow-x: scroll;
  }
  .testall .fi-order-box,
  .fi-order-box ul,
  .sec-order-box {
    width: 700px;
  }
  /* .admin-menus ul li{
  height: initial;
} */
  .testall ul.order-list ul {
    border-right: 1px solid black;
    width: 100% !important;
  }
  .two-lnk {
    display: flex;
    gap: 5px;
    justify-content: center;
  }
  .two-lnk li {
    width: 100px;
    padding: 0 !important;
  }
  .testall.testall ul.order-list li {
    padding-top: 10px;
  }
  .testall ul.order-list {
    grid-template-columns: 17% 17% 17% 32% 17%;
    border-radius: 4px 4px 0px 0px;
    background: var(--Slate);
  }

  .testall ul.order-list ul p {
    border-bottom: 1px solid black;
    color: white;
    margin-top: 10px;
    margin-bottom: 0;
  }
  ul.sec-order-list li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Patient-report-name h1 {
    font-size: 20px;
  }

  .Patient-report-name h2 {
    font-size: 15px;
  }

  .summary h3 {
    font-size: 16px;
    font-weight: 500;
    color: var(--Slate);
  }
  .row.sum-row p {
    font-size: 12px;
    font-weight: 400;
    color: #797f82;
  }
  .Doc-name img {
    width: 25%;
  }

  .Doc-name p {
    margin-bottom: 0;
  }
  .row.sum-row {
    padding: 20px 0 5px;
  }
  .test-nameone {
    background: #7a9ea087;
    padding: 1rem 1rem;
    margin-bottom: 40px;
    border-radius: 20px;
  }
  .mob-chart-adjust div {
    height: 280px;
  }
  .iron-stove {
    padding: 20px 20px;
    border-radius: 20px;
    background: #7a9ea0;
    margin-bottom: 30px;
  }
  .Doc-name {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    gap: 18px;
    background: var(--Pale-Aqua);
    border-radius: 10px;
  }
  .Patient-report-name {
    padding: 20px 3rem;
  }
  .dashboards.customer-dashboard span.arrow-back {
    left: -8px;
  }
  .fertin {
    padding: 20px 10px;
  }
  section.admin-side-side.report-custom {
    padding-left: 0.8rem;
  }
  .mt-4.mob-chart-adjust div {
    height: 300px !important;
  }
  .recomendation-box {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    /* gap: 20px; */
  }
  .inner-recom-box {
    width: 100%;
  }
  h1.recom-title {
    margin-bottom: 10px;
  }
  .recom-right-box .inner-recom-box {
    height: 230px;
  }
  .back-dash p {
    font-size: 15px;
  }
  .content-main-pareny-customer-add-test {
    display: grid;
    grid-template-columns: 4% 96%;
    width: 100%;
  }
  .customer-add-test-input-label-parent select {
    height: 30px;
    font-size: 11px;
    font-weight: 400;
    padding: 0 0.8rem;
  }
  .gapp-right-customer-right-add-test {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .customer-add-test-input-label-parent {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  }
  .Health-Profile-tab h1 {
    font-size: 20px;
    margin: 0;
  }

  .main-Health-Profile-tab .innertitle {
    margin-bottom: 20px;
    color: var(--Slate);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .main-Health-Profile-tab p {
    font-size: 14px !important;
    text-align: center;
  }
  .Health-Profile-Content {
    width: 100%;
    background-color: var(--Pale-Aqua);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 20px 0;
  }
  .Health-Profile-Information h1 {
    font-size: 16px;
    color: var(--Slate);
    margin: 0;
  }
  .Health-Profile-Age label {
    font-size: 14px;
    color: var(--Slate);
  }
  .Health-Profile-Age {
    display: flex;
    flex-direction: column;
  }
  .Health-Profile-Height {
    border: 0px solid var(--Slate);
    width: 100%;
    background-color: white;
    padding: 0px 0;
    border-radius: 0 0 20px 20px;
    color: var(--Slate);
  }
  .Health-Profile-Whats label {
    font-size: 15px !important;
    margin: initial !important;
    display: flex;
    align-items: center;
  }
  .Health-Profile-Age input {
    width: 100px;
    height: 35px;
    border-radius: 7px;
    border: 1px solid var(--Slate);
    margin: 10px 0 20px 0;
    padding: 0 0px 0 18px;
    font-size: 16px;
  }
  .Health-Profile-Height {
    border: 0px solid var(--Slate);
    width: 100%;
    background-color: white;
    padding: 10px 0;
    border-radius: 0 0 20px 20px;
    color: var(--Slate);
  }
  .Health-Profile-smoke label {
    margin: 7px 0;
    font-size: 14px;
  }
  .Health-Profile-smoke input {
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--Slate);
  }
  .Health-Profile-Age label {
    font-size: 14px;
    color: var(--Slate);
  }
  .Health-Profile-smoke label {
    margin: 7px 0;
    font-size: 14px;
  }
  .Health-Profile-smoke h3 {
    font-size: 18px;
  }
  .order-lsit {
    overflow-x: scroll;
  }
  .order-lsit div {
    width: 700px;
  }
  .order-lsit {
    padding: 20px 8px 8px;
    border: 1px solid #d4d0d0;
    border-radius: 10px;
  }
  .orderlist-form {
    width: 100%;
    margin-top: 1rem;
  }
  .order-invoice {
    margin-bottom: 0.5rem;
  }
  .grid-sales-parent {
    overflow-x: scroll;
  }
  .grid-sales-parent div {
    width: 700px;
  }
  .grid-sales-inner.brdre-right-para-sale {
    width: 100%;
  }
  .grid-sales-inner {
    width: 100% !important;
  }
  .grid-sales-inner-cont {
    text-align: center;
  }
  .order-price-detail ul li p {
    width: 100%;
    margin-left: initial;
  }
  .prnt-fi-row-txt-me h4 {
    font-size: 12biomarkerpx;
    font-family: "Poppins";
    font-weight: 500;
    color: #fff;
    margin: 0;
  }

  .biomarker {
    overflow-x: scroll;
  }
  .biomarker div {
    width: 700px;
  }
  .form-btn-check {
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .productadminform label {
    margin-bottom: 0;
    width: 100%;
  }
  .productadminform {
    border: none;
    background: var(--Pale-Aqua);
    padding: 14px 15px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 20px;
  }
  .biomarkertbhead ul li {
    font-size: 11px;
    font-weight: 600;
  }
  .biomarkertbhead ul li input {
    width: 34%;
    font-size: 11px;
    text-align: center;
  }
  .biomarker-table {
    margin-top: 1rem !important;
  }
  .practionar-request .noscroll-mob {
    overflow-x: initial !important;
  }
  .mob-recommendation-scroll {
    overflow-x: scroll;
  }
  .recom-modal-mob .modal-dialog {
    width: 100% !important;
    margin: 0px auto 20px auto !important;
  }

  .form-group .css-1fdsijx-ValueContainer {
    height: 38px !important;
  }
  .showTable {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 15px; */
    margin-bottom: 8px;
    margin-top: 20px;
    align-items: center;
  }
  .search-product input {
    width: 100%;
    padding: 11px 35px;
    margin-right: 0;
  }
  .categoriesFilter {
    display: flex;
    justify-content: center;
  }
  .categoriesFilter select {
    padding: 6px 20px;
  }
  .search-product input {
    padding: 6px 45px;
  }
  .addproduct-tabs li.nav-item .nav-link {
    height: 30px !important;
  }

  .addproduct-tabs ul.nav.nav-tabs {
    gap: 8px;
    border: none;
    margin-bottom: 20px;
    justify-content: center;
  }
  .productadminform .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    height: 38px !important;
  }
  .img-adminproduct {
    width: 100% !important;
    height: 250px !important;
  }
  .productadminform .MuiFormControl-root .css-2m9kme-MuiFormControl-root {
    width: 95% !important ;
  }
  .form-group.uploadimg {
    width: 100% !important;
    height: 250px !important;
  }
  .testall {
    margin-bottom: 0px;
  }
  .plus-icon-img {
    font-size: 40px !important;
  }
  .biomarkeraddbtn {
    margin-bottom: 0 !important;
  }
  .biomarkeraddbtn {
    /* margin-top: 2rem; */
  }
  .form-group.uploadimg.uploadimg2 {
    margin-top: 0rem;
  }
  h1.mean-admin-title {
    font-size: 17px;
    margin-bottom: 0 !important;
  }
  .admin-side-side .d-flex.justify-content-between {
    justify-content: space-between !important;
    align-items: center;
    gap: 5px;
  }
  .customerrecom button {
    font-size: 11px;
  }
  .icon-pr-fr img {
    height: 30px;
    width: 28px;
    padding-bottom: 3px;
  }
  .productadminform .form-group label {
    color: var(--Slate);
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .blood-test-field .row input {
    border: none;
    background: white;
    padding: 7px 30px;
  }
  .blood-test-field .form-group {
    padding-right: initial;
    width: 100%;
  }
  .blood-test-field .row textarea {
    width: 100%;
  }
  .blood-test-field .row {
    padding: 10px 8px;
  }

  .Add-new-test {
    padding: 20px 15px;
  }
  .blood-test-body .blood-test-row ul {
    background: white;
    border: 1px solid var(--Slate);
    margin-top: 10px;
  }
  .test-print {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .test-print img {
    height: 25px;
  }
  .addNcut {
    padding: 10px 20px;
  }
  .cart-out-box-own {
    width: 100% !important;
  }
  .biomarkerlivepreview {
    padding: 10px 20px;
    background: #f3f3f3;
  }

  .previewCheckBox {
    margin: 1rem 0;
  }
  .operators-edit .form-group {
    display: grid !important;
    grid-template-columns: 100%;
  }
  span.password-toggle {
    position: absolute;
    right: 10px;
    top: 68%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
  }

  .prnt-fi-row-txt-me h4 {
    font-size: 14px;
  }
  /* .addproduct-tabs .productadminform label {
  display: block;
  margin: 1rem 0 0;
} */
  /* .testall:last-child {
  display: flex;
  justify-content: center;
} */

  .hexagon-box.first,
  .hexagon-box.second,
  .hexagon-box.Fourth {
    height: 110px;
  }
  /* .modal.show .modal-dialog {
    margin: 0 auto;
    max-width: 100%;
  } */

  /* ADMIN EXTERNAL REPORT */
  .showentries label {
    font-size: 15px;
  }
  select.mesurment {
    border: none;
    background: white;
    padding: 8px 10px;
  }
  .admin-side-side .d-flex.justify-content-between {
    flex-direction: column;
  }
  .productadminform .form-group textarea {
    padding: 8px 10px;
  }
  .cust-gap {
    padding: initial;
  }
  .search-product {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }
  .second-label-2 {
    margin-top: 2rem !important;
  }

  .biomarkers-added-new {
    background: #faf9f7;
    border: 1px solid #d2d2d2;
    padding: 1rem 1rem;
    width: 100%;
    border-radius: 3px;
  }
  .search-product {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .search-product i {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translatey(-50%);
    color: #c4c3c3;
    font-size: 21px;
  }
  .bck-btn-img {
    height: 25px;
  }
  .productadminform .form-group input {
    border: none;
    background: white;
    padding: 8px 14px;
    font-size: 11px;
  }
  .filter-reset button {
    font-size: 13px;
  }
  .filter-reset span {
    font-size: 13px;
  }
  .Health-Profile-smoke label {
    margin: 7px 0;
    font-size: 14px !important;
  }
  .Health-Profile-smoke input {
    padding: 10px;
    font-size: 14px !important;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--Slate);
  }
  .Health-Profile-smoke {
    width: 90% !important;
    margin: 0 auto;
  }
  .btn-shifting-tab {
    font-size: 12px;
  }
  .second-box-customer-add-test input {
    margin-bottom: 0.4rem;
    width: 90%;
    font-size: 13px;
    padding: 5px;
  }
  .customerrecom {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;
    width: 100%;
  }
  .file-upload h5 {
    font-size: 14px;
  }
  .range-of-second-box .css-17vflfl {
    width: 220px;
  }
  .result-second-box-parent {
    width: 100%;
    margin: 1rem auto 0 auto;
  }
  .blood-test-row ul li {
    text-align: left;
    padding-left: 3px;
  }

  .search-product input {
    padding: 8px 0px 8px 39px;
    font-size: 11px;
  }
  .testall ul.sec-order-list {
    grid-template-columns: 17% 17% 17% 15% 17% 17%;
    margin-bottom: 0.5rem;
  }
  .dashboards.product-admin .practionar-request ul.sec-order-list {
    grid-template-columns: 11% 19% 17% 20% 16% 16%;
  }
}
@media only screen and (max-width: 400px) {
  .search-product i {
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translatey(-50%);
    color: #c4c3c3;
    font-size: 21px;
  }
  .search-product input {
    padding: 8px 0px 8px 14px;
    font-size: 11px;
  }

  .customerrecom button {
    font-size: 13px;
    margin-bottom: 9px;
    height: 65px;
  }
  .prnt-fi-row-txt-me h4 {
    font-size: 12px;
  }
  .prnt-sec-row-txt-me p {
    margin: 0;
    font-size: 10px;
  }
  .prnt-sec-row-txt-me p {
    margin: 0;
    font-size: 10px;
  }
  .Health-Profile-smoke input {
    padding: 10px;
    font-size: 14px;
  }
  .Health-Profile-smoke input {
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .biomarkertbhead ul li {
    font-size: 10px;
    font-weight: 600;
  }
  .productadminform label {
    margin-bottom: 0px;
    color: var(--Slate);
    font-size: 11px;
    font-weight: 500;
  }
  .biomarkerbtn button {
    background: var(--Slate);
    padding: 9px 15px;
  }
}
@media only screen and (max-width: 365px) {
  .dashboards.product-admin .blood-test-row ul {
    /* grid-template-columns: 35% 45% 20%; */
  }
  .addproduct-tabs ul.nav.nav-tabs {
    gap: 8px;
    flex-wrap: wrap;
    border: none;
    margin-bottom: 20px;
    justify-content: start;
  }
  .categoriesFilter select {
    padding: 0px 12px;
  }
  .title-test {
    margin-top: 1rem;
  }
  .bybiomarker-head {
    font-size: 12px !important;
  }
  .recommend-btn .biomarkeraddbtn {
    width: 60px !important;
    height: 25px !important;

    font-size: 11px;
  }
  .test-field input {
    padding: 0px 14px;
  }
  .showTable a.login-btn {
    width: 100px;
  }
  .search-product {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 175px;
  }
  .search-product input {
    padding: 8px 0px 8px 28px;
    height: 35px;
    font-size: 10px;
  }

  .search-product i {
    font-size: 18px;
  }
  .productadminform .search-product input {
    background: white;
    color: black;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
  }
  .fade.logoutmodal.modal .modal-footer .cancel-btn {
    margin: 0 0px 0 0;
  }
  .fade.logoutmodal.modal .modal-footer .cancel-btn {
    width: 140px;
  }
}
